/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import Titles from "./Titles";
import { useState } from "react";
import DevNeedPoints from "./DevNeedPoints";
import Button2 from "./Button2";
import { VideoCallIcon } from "./SvgIcons";

function DeveloperNeeds(props: any) {
  const { SdkIntroInHomePage } = props;
  const pointData = [
    { point: "Low Code Low Maintenance", type: "lowcode" },
    { point: "Faster Implementation", type: "fast" },
    { point: "Extensive Documentation", type: "ext" },
    { point: "Better Scaling", type: "better" },
  ];
  // const videoEl = React.useRef<HTMLVideoElement | null>(null);
  // const attemptPlay = () => {
  //   if (!videoEl) return;
  //   videoEl &&
  //     videoEl.current &&
  //     videoEl.current.play().catch((error) => {
  //       console.error("Error attempting to play", error);
  //     });
  // };
  // React.useEffect(() => {
  //   attemptPlay();
  // }, []);
  return (
    <div className=" pt-20 pb-12 text-darkgrey ">
      <Titles
        small_heading={SdkIntroInHomePage?.SdkIntroTitle?.Tagline}
        title={SdkIntroInHomePage?.SdkIntroTitle?.Title}
        sub_heading={SdkIntroInHomePage?.SdkIntroTitle?.Description}
      />
      <div className="flex flex-row justify-center lg:mt-12 p-3  ">
        <div className="p-2  flex flex-col lg:flex-row item-center justify-between  w-[95%] 2xl:w-[70%]  ">
          <div className="flex flex-col md:flex-row lg:flex-col lg:w-1/3 h-full lg:p-2  justify-between ">
            {SdkIntroInHomePage?.VideoEmbedCard.map((node: any) => (
              <div
                className=" md:mx-2 lg:mx-0  my-1 py-2 w-full lg:h-[95px] flex items-center rounded-md bg-[#fcfcfc] lg:bg-white hover:bg-[#fcfcfc] cursor-pointer justify-center border-[1px] border-[#d1d1d1] lg:border-none lg:hover:shadow-md"
                data-aos="zoom-in"
                id="gradient-text"
                key={node.id}
              >
                <DevNeedPoints data={node} />
              </div>
            ))}
          </div>
          <div
            className=" flex  rounded-md shadow-sm justify-center  my-auto w-full h-[380] lg:w-[750px] lg:h-[420px] mt-3"
          // data-aos="zoom-out"
          >
            <div
              className="flex flex-col text-white justify-center "
            // data-aos="zoom-out"
            >
              <div className="flex justify-center m-0 ">
                {/* <iframe
                  src="../sdkvid.mp4"
                  frameBorder="0"
                  width={750}
                  height={421.88}
                  className="rounded-md text-center flex items-center"
                ></iframe> */}
                <img
                  src="../SDKDOCVID.gif"

                  className="rounded-md text-center flex items-center"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-5  " data-aos="zoom-in">
        <Button2 buttontext={"Read More"} link="/hoolva-sdk" />
      </div>
    </div>
  );
}

export default DeveloperNeeds;
