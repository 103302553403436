import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  DotGroup,
  Dot,
  ButtonNext,
} from "pure-react-carousel";
import React, { useLayoutEffect, useState } from "react";
import SingleAboutCard from "./SingleAboutCard";
import { SliderBackwardArrowIcon, SliderForwardArrowIcon } from "./SvgIcons";
import "pure-react-carousel/dist/react-carousel.es.css";
import SingleWhyhoolvaCard from "./SingleWhyhoolvaCard";
import SingleSDKFeatureCard from "./SingleSDKFeatureCard";
import _ from "lodash";

function SDKFeatures(props: any) {

  const [maxTileInSlider, setMaxTileInSlider] = useState(3.5)

  var debounce = require('lodash.debounce');

  const updateSliderSize = () => debounce(
    () => {
      const cardWidth: any = '225'
      const max = Math.floor((vw(100) / cardWidth))
      if (max > 0)
        setMaxTileInSlider(max)

    }, 300)

  const vw = (v: any) => {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    return (v * w) / 100
  }







  useLayoutEffect(() => {

    window.addEventListener("resize", updateSliderSize);
    updateSliderSize();
    return () => window.removeEventListener("resize", updateSliderSize)


  }, [window.innerWidth, document.documentElement.clientWidth])




  const { SDKFeatures } = props;
  const FeaturesData = [
    {
      type: "code",
      title: "Low Code Low Maintenance",
      description:
        "SDKs embeded with APIs to integrate multiple funtionalities",
    },
    {
      type: "speed",
      title: "Faster Implementation",
      description:
        "Quick and reliable funtionality integration with minimal need for support",
    },
    {
      type: "paper",
      title: "Extensive Documentation",
      description:
        "Thorough and instructive documentation to support the integration ",
    },
    {
      type: "key",
      title: "Better Scaling",
      description: "Extensible SDKs that scales with your app",
    },
  ];
  return (
    <div className="py-10 lg:py-20 w-full text-paleblack">
      <div className="sm:w-[90%] lg:w-11/12 bg-blue mx-auto flex flex-col ">
        <div className="text-xl md:text-2xl lg:text-4xl xl:text-5xl text-center lg:text-left  font-bold  p-1 my-2 " data-aos="fade-down">
          Features
        </div>
        {/* --- Desktop View Slider --- */}
        <div className="mx-auto my-5   flex-col w-full   lg:flex hidden">
          <CarouselProvider
            naturalSlideWidth={40}
            naturalSlideHeight={38}
            totalSlides={FeaturesData.length}
            orientation={"horizontal"}
            infinite={true}
            interval={2000}
            visibleSlides={maxTileInSlider}
            className="carousalContainer"
            isPlaying={true}
            dragEnabled={true}
            step={1 / SDKFeatures?.FeatureCard.length}
            dragStep={1 / SDKFeatures?.FeatureCard.length}
          >
            <div
              className="h-full w-5/6 mx-auto rounded-md "
            // data-aos="zoom-in"
            >
              <Slider>
                {SDKFeatures?.FeatureCard.map((node: any, i: any) => (
                  <Slide key={node.id} index={i}>
                    <SingleSDKFeatureCard singleCardData={node} />
                  </Slide>
                ))}
              </Slider>
            </div>

          </CarouselProvider>
        </div>


        {/*--- Mobile View Slider --- */}
        <div className="block lg:hidden">

          <CarouselProvider
            naturalSlideWidth={30}
            naturalSlideHeight={15}
            totalSlides={FeaturesData.length}
            orientation={"horizontal"}
            infinite={false}
            visibleSlides={1}
            className="carousalContainer max-h-[160px] pt-5  flex flex-row item-center  pb-2 justify-center "
            isPlaying={false}
            dragEnabled={true}
            step={1}
            dragStep={1}
            touchEnabled
          >
            <ButtonBack className=" mt-12 h-10  " >
              <div className=" p-2 w-8 h-10 flex justify-center items-center  sliderbutton">
                <SliderBackwardArrowIcon />
              </div>
            </ButtonBack>
            <div
              className=" max-h-[160px] w-[90%] mx-auto rounded-md   p-1  "
            // data-aos="zoom-in"
            >
              <Slider className="max-h-[130px] ">
                {SDKFeatures?.FeatureCard.map((node: any, i: any) => (
                  <Slide key={node.id} index={i}>
                    <SingleSDKFeatureCard singleCardData={node} />
                  </Slide>
                ))}
              </Slider>
            </div>
            <ButtonNext className=" mt-12 h-10  ">
              <div className=" p-2 w-8 h-10 flex justify-center items-center  sliderbutton ">
                <SliderForwardArrowIcon />
              </div>
            </ButtonNext>
          </CarouselProvider>



        </div>
      </div>
    </div>
  );
}

export default SDKFeatures;
