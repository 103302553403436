import React, { useEffect, useState } from "react";
import {
  CommmunicatorBigIcon,
  EducationBigIcon,
  HeadPhonePersonBigIcon,
  MedicalBigIcon,
  WebinarBigIcon,
} from "./SvgIcons";
import ReactCardFlip from "react-card-flip";

function SingleUseCaseCard(props: any) {
  const [hover, setHover] = useState<any>(false);

  const { singleCardData } = props;

  return (
    <>
      <ReactCardFlip
        isFlipped={hover}
        flipDirection="horizontal"
        flipSpeedBackToFront={1}
        flipSpeedFrontToBack={1}

      >
        <div
          className="flex flex-row justify-center items-center   cursor-pointer w-[220px] h-[220px]  xl:w-[280px] xl:h-[280px] 2xl:h-[320px] 2xl:w-[320px] rounded-lg  border-transparent mx-auto "
          onMouseOver={() => setHover(true)}
        >
          <div className="px-8 py-8 h-full w-full justify-center flex flex-col ">
            <div>
              <div className="flex-row w-auto  p-3 flex justify-center scale-[.4] md:scale-[0.6] lg:scale-[0.8] xl:scale-[1]">
                {singleCardData?.IconType === "comm" ? (
                  <CommmunicatorBigIcon />
                ) : singleCardData?.IconType === "medical" ? (
                  <MedicalBigIcon />
                ) : singleCardData?.IconType === "recruit" ? (
                  <HeadPhonePersonBigIcon />
                ) : singleCardData?.IconType === "education" ? (
                  <EducationBigIcon />
                ) : singleCardData?.IconType === "laptop" ? (
                  <WebinarBigIcon />
                ) : null}
              </div>
              <h5 className=" text-md md:text-md  lg:text-md xl:text-xl 2xl:text-2xl  font-bold font-body text-center tracking-tight text-paleblack dark:text-white py-2  p-3">
                {singleCardData?.Title}
              </h5>
            </div>
          </div>
        </div>
        {/* bg-gradient-to-r from-orange-400 to-orange-600 */}
        <div
          className="flex flex-row justify-center items-center  cursor-pointer  lg:w-[220px] lg:h-[220px]   xl:w-[280px] xl:h-[280px] 2xl:h-[320px] 2xl:w-[320px] rounded-lg border-transparent border  mx-auto "
          onMouseLeave={() => setHover(false)}
        >
          <div className=" h-full w-fit justify-center font-body bg-gradient-to-r xl:p-1 from-orange-400 to-orange-600 text-xs  md:text-md xl:text-lg  2xl:text-xl  rounded-lg  text-center  flex cursor-pointer px-1 ">
            <div className="my-auto">{singleCardData.Description}</div>
          </div>
        </div>
      </ReactCardFlip>
    </>
  );
}

export default SingleUseCaseCard;
