import React from "react";
import { BigCodeIcon, BigKeyIcon, PaperIcon, SpeedIcon } from "./SvgIcons";

function SingleSDKFeatureCard(props: any) {
  const { singleCardData } = props;
  return (
    <div
      className="flex flex-row justify-center bg-[#fcfcfc] lg:hover:bg-white shadow-md lg:hover:shadow-md cursor-pointer w-[95%] mx-auto lg:mx-0 h-[130px] lg:h-[100%] rounded-lg border border-gray-200 pt-2"
      data-aos="zoom-in"
    >
      <div className=" h-full w-full  mx-2 justify-start flex flex-col  ">
        <div className=" hidden lg:flex flex-row w-auto lg:h-14 p-3  items-center ">
          {singleCardData?.IconType === "codebox" ? (
            <BigCodeIcon />
          ) : singleCardData?.IconType === "speedometer" ? (
            <SpeedIcon />
          ) : singleCardData?.IconType === "blankdocument" ? (
            <PaperIcon />
          ) : singleCardData?.IconType === "key" ? (
            <BigKeyIcon />
          ) : null}
        </div>
        <h5 className="text-md md:text-md  text-center lg:text-left  lg:text-md xl:text-xl 2xl:text-2xl  font-bold  lg:h-20  font-body tracking-tight text-paleblack dark:text-white py-2  p-3 ">
          {singleCardData?.Title}
        </h5>
        <p className=" font-normal text-paleblack   text-center lg:text-left lg:h-36 text-xs md:text-sm md:text-md xl:text-xl 	 dark:text-gray-400 font-body p-3 ">
          {singleCardData?.Description}
        </p>
      </div>
    </div>
  );
}

export default SingleSDKFeatureCard;
