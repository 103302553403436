/* eslint-disable react/jsx-pascal-case */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import Navbar from "../Components/Header";
import HomeFace from "../Components/HomeFace";
import Partners from "../Components/Partners";
import Marketneed from "../Components/Marketneed";
import WhyHoolva from "../Components/WhyHoolva";
import DeveloperNeeds from "../Components/DeveloperNeeds";
import Demandarea from "../Components/Demandarea";
import HoolvaTeam from "../Components/HoolvaTeam";
import Pricing from "../Components/Pricing";
import Contact_us from "../Components/Contact_us";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { useSelector } from "react-redux";

function Home() {
  const HomeData: any = useSelector(
    (state: any) => state?.HomePage?.HomePageContent
  );

  // console.log("HOME", HomeData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <HomeFace HomeFaceData={HomeData?.HomePageFace} />
      <Marketneed HomePageIntro={HomeData?.HomePageIntro} />
      <WhyHoolva WhyHoolva={HomeData?.WhyHoolva} />
      <DeveloperNeeds SdkIntroInHomePage={HomeData?.SdkIntroInHomePage} />
      <Demandarea BusinessVerticals={HomeData?.BusinessVerticals} />
      <HoolvaTeam HoolvaTeam={HomeData?.HoolvaTeam} />
      <Pricing Pricing={HomeData?.Pricing} />
      <Contact_us PartnerWithUsContent={HomeData?.PartnerWithUsContent} />
      <Footer />
    </div>
  );
}

export default Home;
