import React from "react";
import Button1 from "./Button1";
import Button2 from "./Button2";
import { SDKBox1 } from "./SvgIcons";

function HoolvaSDK(props: any) {
  const { HoolvaSDKContent } = props;
  return (
    <div className="w-full bg-[#FDE9D2] sm:py-20 text-paleblack">
      <div className="w-full lg:w-5/6  mx-auto flex flex-col sm:flex-row p-1 ">
        <div
          className="w-full md:w-1/2 h-[350px]   p-1 flex  justify-center   "
        // data-aos="fade-down"
        // data-aos-anchor-placement="top-center"
        >
          <SDKBox1 />
        </div>
        <div
          className="w-full md:w-1/2  flex-col flex p-1 justify-start my-auto "
          data-aos="fade-up"
        // data-aos-anchor-placement="top-center"
        >
          <div className=" flex items-center justify-start p-2 ">
            <hr className="border-orange-600 w-24  border-[1.5px] bg-orange-600" />
          </div>
          <div
            className=" flex justify-start "
          //  data-aos="fade-in"
          >
            <div className="font-body text-sm md:text-md lg:text-lg xl:text-xl font-thin text-paleblack p-2">
              {HoolvaSDKContent?.Tagline}
            </div>
          </div>

          <div
            className="flex  justify-start"
          // data-aos="fade-in"
          >
            <div className="w-11/12 text-left  p-2 ">
              <div className="text-xl md:text-2xl lg:text-4xl xl:text-5xl font-bold font-body text-paleblack">
                {HoolvaSDKContent?.Title}
              </div>
            </div>
          </div>

          <div
            className=" flex  justify-start text-left p-2"
          // data-aos="fade-up"
          >
            <div className="text-sm md:text-md lg:text-lg xl:text-xl font-body p-2 font-light flex-col">
              <div>{HoolvaSDKContent?.Description1}</div>
              <br />
              <div>{HoolvaSDKContent?.Description2}</div>
            </div>
          </div>
          <div className="= p-1 flex flex-row  mx-3 my-5 sm:my-10">
            {/* <Button2 buttontext={" Get API Key"} /> */}

            {/* <Button1 buttontext={"View Documentation"} color={"paleblack"} /> */}
            <a href="https://docs.hoolva.com">
              <div>
                <button
                  className={`mr-2 bg-transparent font-body  text-paleblack  font-medium text-sm md:text-md lg:text-lg xl:text-xl py-1 px-2 lg:py-2 lg:px-4 border border-orange-600 hover:border-transparent rounded-[5px]`}
                >
                  {HoolvaSDKContent?.HoverButtonText}
                </button>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HoolvaSDK;
