import React from "react";
import Titles from "./Titles";

function PartnershipResgister(props: any) {
  const { JoinPartnership } = props;
  return (
    <div className="bg-white w-full py-10 ">
      <div className="mx-auto  p-2 w-4/5  flex flex-col justify-center ">
        <Titles
          color="paleblack"
          small_heading={""}
          title={JoinPartnership?.Title}
          sub_heading={JoinPartnership?.Tagline}
        />
        {/* <div className="p-2 my-10 flex flex-row justify-center items-center">
          <button
            className={`mr-2 bg-transparent  w-[187px] font-body   text-black  font-medium  py-2 px-4 border border-orange-600 hover:border-transparent rounded-[5px]`}
          >
            {"Partner Registration"}
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default PartnershipResgister;
