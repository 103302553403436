import React from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Titles(props: any) {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      useClassNames: true,
      animatedClassName: "animated",
    });
    AOS.refresh();
  }, []);
  return (
    <div data-aos="fade-in " className="w-[90%] text-darkgrey justify-center mx-auto">
      <div className=" flex items-center justify-center ">
        <hr className="border-orange-600 w-20 mb-2 border-[1.5px] bg-orange-600" />
      </div>
      <div className=" flex items-center justify-center text-sm md:text-md xl:text-xl">
        <div className={`font-body text-${props.color}`}>
          {props.small_heading}
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className=" xl:w-5/12 content-center text-center mt-3">
          <div className={`text-xl md:text-2xl lg:text-4xl xl:text-5xl font-bold font-body text-${props.color}`}>
            {props.title}
          </div>
        </div>
      </div>
      <div className=" flex items-center justify-center px-2 xl:px-0">
        <div
          className={`w-719 font-body content-center mt-3	text-sm xl:text-xl text-center text-${props.color}`}
        >
          {props.sub_heading}
        </div>
      </div>
    </div>
  );
}

export default Titles;
