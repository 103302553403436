import React from "react";

function ContactMap() {
  return (
    <div className="w-full bg-[#404041] p-2  xl:flex items-center py-10">
      <div className="xl:w-[70%]  mx-auto p-1 flex flex-col xl:flex-row items-center xl:justify-around justify-center">
        <div className="xl:p-3 w-full xl:w-[450px]  " data-aos="fade-up">
          <div className="flex flex-row  my-1 p-3 border-[#D7D7D7] border-b-[1px] text-white">
            <div className="w-[50px]  justify-center flex">
              <svg
                width="22"
                height="27"
                viewBox="0 0 22 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9993 13.1667C11.6438 13.1667 12.194 12.9391 12.65 12.484C13.1051 12.028 13.3327 11.4778 13.3327 10.8333C13.3327 10.1889 13.1051 9.63867 12.65 9.18267C12.194 8.72756 11.6438 8.5 10.9993 8.5C10.3549 8.5 9.80468 8.72756 9.34868 9.18267C8.89357 9.63867 8.66602 10.1889 8.66602 10.8333C8.66602 11.4778 8.89357 12.028 9.34868 12.484C9.80468 12.9391 10.3549 13.1667 10.9993 13.1667ZM10.9993 23.9C13.7549 21.4556 15.8327 19.1164 17.2327 16.8827C18.6327 14.6498 19.3327 12.7222 19.3327 11.1C19.3327 8.56667 18.5273 6.5 16.9167 4.9C15.3051 3.3 13.3327 2.5 10.9993 2.5C8.66602 2.5 6.69357 3.3 5.08202 4.9C3.47135 6.5 2.66602 8.56667 2.66602 11.1C2.66602 12.7222 3.36602 14.6498 4.76602 16.8827C6.16602 19.1164 8.24379 21.4556 10.9993 23.9ZM10.9993 26.5667C7.53268 23.5444 4.94379 20.7444 3.23268 18.1667C1.52157 15.5889 0.666016 13.2333 0.666016 11.1C0.666016 7.87778 1.70513 5.30533 3.78335 3.38267C5.86068 1.46089 8.26602 0.5 10.9993 0.5C13.7327 0.5 16.1385 1.46089 18.2167 3.38267C20.294 5.30533 21.3327 7.87778 21.3327 11.1C21.3327 13.2333 20.4771 15.5889 18.766 18.1667C17.0549 20.7444 14.466 23.5444 10.9993 26.5667Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="ml-3  w-[300px]  md:text-[14px] font-body text-[16px] lg:text-[18px]">
              101 Crawfords Corner Rd. #4-116, 1-158
              Holmdel,<br /> NJ 07733 +1 855 -
              8 HOOLVA
            </div>
          </div>
          <div className="flex flex-row  my-1 p-3">
            <div className="w-[50px]  justify-center flex">
              <svg
                width="26"
                height="21"
                viewBox="0 0 26 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.73398 20.5C2.06732 20.5 1.50065 20.2667 1.03398 19.8C0.567318 19.3333 0.333984 18.7667 0.333984 18.1V2.9C0.333984 2.23333 0.567318 1.66667 1.03398 1.2C1.50065 0.733333 2.06732 0.5 2.73398 0.5H23.2673C23.934 0.5 24.5007 0.733333 24.9673 1.2C25.434 1.66667 25.6673 2.23333 25.6673 2.9V18.1C25.6673 18.7667 25.434 19.3333 24.9673 19.8C24.5007 20.2667 23.934 20.5 23.2673 20.5H2.73398ZM13.0007 11.2333L2.33398 4.43333V18.1C2.33398 18.2111 2.3731 18.3058 2.45132 18.384C2.52865 18.4613 2.62287 18.5 2.73398 18.5H23.2673C23.3784 18.5 23.4731 18.4613 23.5513 18.384C23.6287 18.3058 23.6673 18.2111 23.6673 18.1V4.43333L13.0007 11.2333ZM13.0007 9.16667L23.4673 2.5H2.53398L13.0007 9.16667ZM2.33398 4.43333V2.5V18.1C2.33398 18.2111 2.3731 18.3058 2.45132 18.384C2.52865 18.4613 2.62287 18.5 2.73398 18.5H2.33398V18.1V4.43333Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="ml-3  font-semibold font-body text-[#4B8AE9] text-[18px]">ping@hoolva.com
            </div>
          </div>
        </div>

        <div data-aos="fade-down "
          className=" w-full justify-center"
        >
          <iframe
            title="map"
            className="rounded-[10px] xl:rounded-[20px] w-[90%] lg:h-[300px] h-[150px] sm:h-[200px] md:h-[200px] xl:h-[350px] xl:w-[667px] mx-auto"
            width="667px"
            height="350px"
            frameBorder="1"
            loading="lazy"

            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48639.593494535366!2d-74.23749484179687!3d40.365087700000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2336cc3eccd5b%3A0xfb8f82ff4f96828a!2sHoolva%20Labs!5e0!3m2!1sen!2sin!4v1667554601907!5m2!1sen!2sin"
          />
        </div>
      </div>
    </div>
  );
}

export default ContactMap;
