export const HOMEPAGE = "HOMEPAGE";
export const ABOUTPAGE = "ABOUTPAGE";
export const CONTACTPAGE = "CONTACTPAGE";
export const SOLUTIONPAGE = "SOLUTIONPAGE";
export const LOGO = "LOGO";
export const SDKPAGE = "SDKPAGE";
export const ENV = "ENV";
export const FOOTER = "FOOTER";
export const SYSTEMSTATUS = "SYSTEMSTATUS";
export const CAREERS = "CAREERS";
export const POSITIONLIST = "POSITIONLIST";
export const SUPPORT = "SUPPORT";
export const PARTNERSHIP = "PARTNERSHIP";
export const CASESTUDYPAGE="CASESTUDYPAGE";
export const CASESTUDYLIST="CASESTUDYLIST"

export const EnvironmentAction = (data: any) => {
  return {
    type: ENV,
    payload: data,
  };
};

export const SystemStatusPageAction = (data: any) => {
  return {
    type: SYSTEMSTATUS,
    payload: data,
  };
};

export const PositionListAction = (data: any) => {
  return {
    type: POSITIONLIST,
    payload: data,
  };
};

export const SupportPageAction = (data: any) => {
  return {
    type: SUPPORT,
    payload: data,
  };
};

export const PartnershipPageAction = (data: any) => {
  return {
    type: PARTNERSHIP,
    payload: data,
  };
};

export const CareersPageAction = (data: any) => {
  return {
    type: CAREERS,
    payload: data,
  };
};

export const HomePageAction = (data: any) => {
  return {
    type: HOMEPAGE,
    payload: data,
  };
};

export const SDKPageAction = (data: any) => {
  console.log("1111", data);

  return {
    type: SDKPAGE,
    payload: data,
  };
};

export const AboutPageAction = (data: any) => {
  return {
    type: ABOUTPAGE,
    payload: data,
  };
};

export const ContactPageAction = (data: any) => {
  return {
    type: CONTACTPAGE,
    payload: data,
  };
};

export const SolutionPageAction = (data: any) => {
  return {
    type: SOLUTIONPAGE,
    payload: data,
  };
};

export const CaseStudyPageAction = (data: any) => {
  return {
    type: CASESTUDYPAGE,
    payload: data,
  };
};

export const CaseStudyListAction = (data: any) => {
  return {
    type: CASESTUDYLIST,
    payload: data,
  };
};

export const LogoAction = (data: any) => {
  return {
    type: LOGO,
    payload: data,
  };
};

export const FooterAction = (data: any) => {
  return {
    type: FOOTER,
    payload: data,
  };
};
