// const env = "http://localhost:1337";

// Production
const env = "https://hoolva.com/api";
//test
// const env = "https://us3-server-node0.hoolva.com/api";
//staging
// const env = "https://us2-cluster.hoolva.com/api";
// const env = "https://us4-cluster.hoolva.com/api";




export default env;
