import axios from "axios";
import React, { useRef, useState } from "react";
import env from "../config";

function JobApplyForm(props: any) {
  const { jobName } = props;
  const hiddenFileInput = useRef(null);
  const [fileName, setFileName] = useState("No File Choosen");
  const [filestate, setFileState] = useState<any>({ file: null });
  const [fileId, setFileId] = useState<any>("");

  const handleFileChange = (event: any) => {
    setFileState({ file: event.target.files[0] });
    setFileName(event.target.files[0].name);
    // setFileName()
    // console.log("HandleChange Event", event.target.files);
  };

  // console.log("CONTACT DTA", ContactData);
  const emptyState = {
    fullName: "",
    phone: "",
    email: "",
    CV: "",
    coverLetter: "",
    jobName: jobName,
  };

  const [formData, setFormData] = useState(emptyState);
  const [flag, setFlag] = useState("initial");

  const handleForm = (e: any) => {
    const newFormData: any = { ...formData };
    // if (e.target.id === "file") {
    //   const file = new File(e.target.files[0], "");
    //   console.log("fileData", file);
    //   setFileName(file.name);
    //   // fileData.append("document", e.target.value as Blob);
    //   // newFormData[e.target.id] = fileData;
    // } else {
    newFormData[e.target.id] = e.target.value;
    newFormData["jobName"] = jobName;
    // }
    setFormData(newFormData);

    // setFormData((data: any) => {
    //   return (data[e.target.id] = e.target.value);
    // });
  };

  const UploadToLibrary = async () => {
    const data = new FormData();
    data.append("files", filestate.file);
    if (filestate.file !== null)
      return await axios.post(`${env}/api/upload/`, data);
    return null;
    // .then((res: any) => {
    //   console.log("Response :", res);
    //   setFileId(res.data[0].id);
    //   // setFileName(res.data[0].name);
    //   const formWithFileId: any = { ...formData };
    //   formWithFileId["CV"] = res.data[0].id;
    //   setFormData(formWithFileId);
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  };

  const handleFileUploadClick = () => {
    //@ts-ignore
    hiddenFileInput.current.click();
  };

  // const sendFormData = () => {};

  const sendMail = async () => {
    // e.preventDefault();

    await UploadToLibrary()
      .then(async (res) => {
        if (res === null) setFlag("notcomplete");
        if (res !== null) {
          console.log("Response :", res);
          setFileId(res.data[0].id);
          // setFileName(res.data[0].name);
          const formWithFileId: any = { ...formData };
          formWithFileId["CV"] = res.data[0].id;
          // setFormData(formWithFileId);
          console.log("sendMail", formData);
          if (
            formWithFileId.fullName === "" ||
            formWithFileId.phone === "" ||
            formWithFileId.coverLetter === "" ||
            formWithFileId.email === "" ||
            formWithFileId.CV === ""
          ) {
            setFlag("notcomplete");
          } else {
            setFlag("loading");
            console.log("sendMail", formWithFileId);
            await axios
              .post(`${env}/api/career-emails`, {
                data: formWithFileId,
              })
              .then(() => {
                setFlag("success");
                setTimeout(() => {
                  setFlag("initial");
                }, 10000);
                setFormData(emptyState);
              })
              .catch((e) => {
                console.log("Error ", e);
                setFlag("error");
                setFormData(emptyState);
              });
          }
        }
      })
      .catch((e) => {
        console.log("errror", e);
      });
  };

  return (
    <div className="w-full mx-auto py-5 lg:py-20 xl:py-28 pb-4 bg-[#F4F4F4]  ">
      <div
        className=" font-bold md:mx-8 mx-5 lg:mx-10 p-2  justify-center lg:justify-start"
        data-aos="fade-in "
      >
        <hr className="border-orange-600 w-24 mb-4 border-[1.5px] bg-orange-600" />
        <div className="text-3xl lg:text-4xl  xl:text-5xl">
          Apply for this position{" "}
        </div>
      </div>
      <div
        className="flex flex-col bg-white w-[95%] xl:w-3/6 mx-auto py-3 rounded-md my-10 shadow-xl"
        data-aos="zoom-in"
      >
        {/* <form
        action="#"
        // onSubmit={(e) => sendMail(e)}
        // method="post"
        encType="text/plain"
      > */}

        <div className="flex md:flex-row  px-3 justify-between  flex-col">
          <div className="flex flex-col p-3 xl:w-1/2 w-full  mx-1">
            <label className="text-[#868686] md:text-[12px] text-[14px] lg:text-[16px]">
              Full Name *
            </label>
            <input
              type="text"
              className="border-[1px] border-[#B0B0B0] rounded-md h-10 px-3 py-2 text-[14px] placeholder:text-[14px] placeholder:text-[#D7D7D7]"
              placeholder="First Name Last Name"
              id="fullName"
              value={formData.fullName}
              onChange={(e) => {
                handleForm(e);
                setFlag("initial");
              }}
              required
            />
          </div>
          <div className="flex flex-col  p-3 xl:w-1/2 w-full mx-1">
            <label className="text-[#868686] md:text-[12px] text-[14px] lg:text-[16px]">
              Email *
            </label>
            <input
              required
              value={formData.email}
              className="border-[1px] border-[#B0B0B0] rounded-md h-10 px-3 py-2 text-[14px] placeholder:text-[14px] placeholder:text-[#D7D7D7]"
              placeholder="example@gmail.com"
              type="email"
              id="email"
              onChange={(e) => {
                handleForm(e);
                setFlag("initial");
              }}
            />
          </div>
        </div>
        <div className="flex md:flex-row  px-3 justify-between flex-col">
          <div className="flex flex-col p-3 xl:w-1/2 w-full mx-1">
            <label className="text-[#868686] md:text-[12px] text-[14px] lg:text-[16px]">
              Phone *
            </label>
            <input
              required
              value={formData.phone}
              className="border-[1px] border-[#B0B0B0] rounded-md h-10 px-3 py-2 text-[14px] placeholder:text-[14px]  placeholder:text-[#D7D7D7]"
              placeholder="- - -  - - -  - - - - - -  -"
              type="text"
              id="phone"
              onChange={(e) => {
                handleForm(e);
                setFlag("initial");
              }}
            />
          </div>
          <div className="flex flex-col p-3  mx-1 xl:w-1/2 w-full">
            <label className="text-[#868686] md:text-[12px] text-[14px] lg:text-[16px]">
              Attach CV *
            </label>
            <div className="border-[1px] justify-between  border-[#B0B0B0] item-center flex rounded-md h-10 px-3 py-2 text-[14px] placeholder:text-[14px] placeholder:text-[#D7D7D7]">
              <input
                style={{ display: "none" }}
                required
                // value={formData.file}
                // className="border-[1px]  border-[#B0B0B0] item-center flex rounded-md h-10 px-3 py-2 text-[14px] placeholder:text-[14px] placeholder:text-[#D7D7D7]"
                placeholder="No file choosen"
                type="file"
                id="file"
                ref={hiddenFileInput}
                //   accept="image/png"
                // onChange={(e) => {
                //   handleForm(e);
                //   setFlag("initial");
                // }}
                onChange={(e) => {
                  handleFileChange(e);
                }}
              />
              <div className=" text-[#D7D7D7] flex items-center max-w-[70%] text-ellipsis truncate  overflow-hidden">
                {fileName}
              </div>
              <div
                className=" text-[10px] sm:text-[12px] md:text-[14px] lg:text-[16px] px-1 lg:px-3 py-1 w-fit rounded-md items-center flex border-[1px] cursor-pointer border-[#F65E1D]"
                onClick={handleFileUploadClick}
              >
                Choose file
              </div>
            </div>
          </div>
        </div>
        <div className="px-4">
          <div className="flex flex-col p-3 ">
            <label
              htmlFor=""
              className="text-[#868686] md:text-[12px] text-[14px] lg:text-[16px]"
            >
              Cover Letter *
            </label>
            <textarea
              required
              value={formData.coverLetter}
              name="coverLetter"
              id="coverLetter"
              onChange={(e) => {
                handleForm(e);
                setFlag("initial");
              }}
              // cols={30}
              rows={4}
              placeholder="Jot down your query here..."
              className="border-[1px] resize-none border-[#B0B0B0] rounded-md  px-3 py-2 text-[14px] placeholder:text-[14px] placeholder:text-[#D7D7D7]"
            ></textarea>
          </div>
        </div>
        <div className="  mx-8 xl:w-3/4  flex flex-row align-top items-start p-1 xl:justify-between">
          <div className="px-2 py-2">
            <input type="checkbox" />
          </div>

          <div className=" p-1 text-xs md:text-[12px] text-[14px] lg:text-[16px]">
            By using this form you agree with the storage and handling of your
            data by this website. *
          </div>
        </div>
        <div className="px-7 py-2 flex  justify-center xl:justify-end">
          <button
            // type="submit"
            // type="reset"

            type="button"
            disabled={
              flag === "loading"
                ? true
                : flag === "initial"
                ? false
                : flag === "notcomplete"
                ? true
                : false
            }
            onClick={() => sendMail()}
            style={{
              cursor:
                flag === "notcomplete" || flag === "loading"
                  ? "not-allowed"
                  : "pointer",
            }}
            className="bg-gradient-to-r from-orange-400 to-orange-600 text-black  font-body font-medium py-1 px-2 lg:py-2 lg:px-4 border border-orange-600 hover:border-transparent rounded text-sm md:text-md"
          >
            {flag === "loading"
              ? "Sending.."
              : flag === "initial"
              ? "Submit"
              : flag === "success"
              ? "Submit"
              : "Submit"}
          </button>
          {/* </form> */}
        </div>
        <div className="px-6  flex ">
          {flag === "success" && (
            <div className="flex flex-col bg-white text-[#89D074] w-full mx-auto p-2 md:p-3 border-[#89D074] border-2 rounded-md mt-2 font-semibold md:text-sm text-xs">
              Success
              <div className=" py-1 md:py-2 text-[#89D074] font-normal">
                Your response was submitted
              </div>
            </div>
          )}
          {flag === "error" && (
            <div className="flex flex-col bg-white text-[#EE5F5F] w-full mx-auto p-2 md:p-3 border-[#EE5F5F] border-2 rounded-md mt-2 font-semibold md:text-sm text-xs">
              Error
              <div className=" py-1 md:py-2 text-[#EE5F5F] font-normal">
                There seems to be some error . Please try again
              </div>
            </div>
          )}
          {flag === "notcomplete" && (
            <div className="flex flex-col bg-white text-blue-500 w-full mx-auto p-2 md:p-3 border-blue-500 border-2 rounded-md mt-2  font-semibold md:text-sm text-xs">
              Incomplete form
              <div className=" py-1 md:py-2 text-blue-500 font-normal">
                Please fill all fields to submit
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default JobApplyForm;
