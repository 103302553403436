import React from "react";
import {
  PartnershipIcon,
  CodeIcon,
  ConfrenceIcon,
  SharpHandshakeIcon,
  PartnershipIcon1,
} from "./SvgIcons";
import Titles from "./Titles";

function PartnershipVerticals(props: any) {
  const { PartnershipVerticals } = props;
  return (
    <div className=" py-10 md:py-20 bg-[#F4F4F4] text-paleblack " id="#partnership-verticals">
      <div className="mx-auto  p-2 w-[100%] lg:w-4/5 ">
        <Titles
          color="paleblack"
          small_heading={PartnershipVerticals?.TitleContent?.Tagline}
          title={PartnershipVerticals?.TitleContent?.Title}
          sub_heading={PartnershipVerticals?.TitleContent?.Description}
        />
        <div className="flex flex-col md:flex-row p-2 w-full  mx-auto mt-10 md:mt-20  justify-center">
          <div
            data-aos="zoom-in"
            className=" md:w-[280px] mb-3 md:mb-0  sm:h-[200px] md:h-[250px] bg-white rounded-md mx-1 relative overflow-hidden border-[1px] border-[#F65E1D]"
          >
            <div className="w-[150px]  h-[150px] sm:w-[120px] sm:h-[120px] bg-[#f0f0f033] left-[250px] sm:left-[200px] rounded-full top-[-40px] absolute "></div>
            <div className="flex flex-col  p-2 m-3">
              <div className="p-2 rounded-xl bg-[#D7D7D733] w-[64px] h-[64px] my-1  hidden sm:flex justify-center items-center">
                <SharpHandshakeIcon />
              </div>
              <div className="font-semibold text-[14px] sm:text-[18px] my-1">
                {PartnershipVerticals?.Card1?.Title}
              </div>
              <div className="text-[12px] sm:text-[16px] my-1">
                {PartnershipVerticals?.Card1?.Description}
              </div>
            </div>
          </div>

          <div
            data-aos="zoom-in"
            className="md:w-[280px] sm:h-[200px] md:h-[250px]  mb-3 md:mb-0 bg-[#FFDCC9] rounded-md mx-1 relative overflow-hidden border-[1px] border-[#F65E1D]"
          >
            <div className="w-[150px]  h-[150px] sm:w-[120px] sm:h-[120px] bg-[#FFFFFF33] left-[250px] sm:left-[200px] rounded-full top-[-40px] absolute "></div>
            <div className="flex flex-col p-2 m-3">
              <div className="p-2 rounded-xl bg-[#D7D7D733]  w-[64px] h-[64px] my-1  hidden sm:flex justify-center items-center">
                <CodeIcon />
              </div>
              <div className="font-semibold text-[14px] sm:text-[18px] my-1">
                {PartnershipVerticals?.Card2?.Title}
              </div>
              <div className="text-[12px] sm:text-[16px] my-1">
                {PartnershipVerticals?.Card2?.Description}
              </div>
            </div>
          </div>

          <div
            data-aos="zoom-in"
            className="md:w-[280px] sm:h-[200px] md:h-[250px] mb-3 md:mb-0 bg-[#FFD4A1] rounded-md mx-1 relative overflow-hidden border-[1px] border-[#F65E1D]"
          >
            <div className=" w-[150px]  h-[150px] sm:w-[120px] sm:h-[120px] bg-[#FFFFFF33] left-[250px] sm:left-[200px] rounded-full top-[-40px] absolute "></div>
            <div className="flex flex-col  p-2 m-3">
              <div className="p-2 rounded-xl bg-[#D7D7D733]  w-[64px] h-[64px] my-1 hidden sm:flex justify-center items-center ">

                <ConfrenceIcon />

              </div>
              <div className="font-semibold text-[14px] sm:text-[18px] my-1">
                {PartnershipVerticals?.Card3?.Title}
              </div>
              <div className="text-[12px] sm:text-[16px] my-1">
                {PartnershipVerticals?.Card3?.Description}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnershipVerticals;
