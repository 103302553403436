import React from "react";
import Titleleft from "./Titleleft";
import SingleDemandCard from "./SingleDemandCard";

function Demandarea(props: any) {
  const { BusinessVerticals } = props;
  const CardsData = [
    { id: 1, type: "govt", text: "Government Agencies" },
    { id: 2, type: "doller", text: "Finance" },
    { id: 3, type: "cast", text: "Broadcasting" },
    { id: 4, type: "health", text: "Healthcare" },
    { id: 5, type: "erp", text: "ERP" },
    { id: 6, type: "confrence", text: "Conferences" },
    { id: 7, type: "edu", text: "Education" },
    { id: 8, type: "sales", text: "Sales & Support" },
    { id: 9, type: "stream", text: "Live streaming" },
  ];
  return (
    <div className="bg-pink flex md:pt-10 pb-8 flex-row ">
      <div className=" flex flex-col md:flex-row p-2 mx-auto">
        <div className=" w-fit justify-center mx-auto  md:px-8 ">
          <div
            className="flex flex-col mx-auto w-[100%] md:w-[90%] xl:w-484 text-center  md:text-left p-2  mt-16"
            data-aos="fade-down"
          >
            <hr className="border-orange-500 border-[1.5px] mx-auto md:mx-0 bg-orange-500 w-20 mb-4" />
            <div className=" my-1 font-body text-paleblack text-sm md:text-md xl:text-xl">
              {BusinessVerticals?.Content?.Tagline}
            </div>
            <div className=" text-xl md:text-2xl lg:text-4xl xl:text-4xl font-bold my-1 text-paleblack">
              {BusinessVerticals?.Content?.Title}
            </div>
            <div className=" my-1 font-body text-paleblack text-sm md:text-md xl:text-xl ">
              {BusinessVerticals?.Content?.Description}
            </div>
          </div>
          <div></div>
        </div>
        <div className=" w-fit grid grid-rows-3  grid-flow-col p-2  gap-x-2 mx-auto">
          {CardsData.map((node: any) => (
            <SingleDemandCard key={node.id} singleCardData={node} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Demandarea;
