import Footer from "../Components/Footer";
import Header from "../Components/Header";
import DeploymentSection from "../Components/DeploymentSection";
import HoolvaUnique from "../Components/HoolvaUnique";
import CspsSolutions from "../Components/CspsSolutions";
import DeploymentOptions from "../Components/DeploymentOptions";
import HoolvaStructure from "../Components/HoolvaStructure";
import SolutionCaseStudies from "../Components/SolutionCaseStudies";
import SolutionFace from "../Components/SolutionFace";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../redux/actions";
import env from "../config";

function SolutionPage() {
  const dispatch = useDispatch();
  const SolutionData: any = useSelector(
    (state: any) => state?.SolutionPage?.SolutionPageContent
  );

  useEffect(() => {
    if (Object.keys(SolutionData).length === 0) {
      getSolutionPageData();
    }

    window.scrollTo(0, 0);
  }, []);

  const getSolutionPageData = async () => {
    await axios
      .get(`${env}/api/solution-page-content?populate=deep`)
      .then((response: any) => {
        dispatch(Actions.SolutionPageAction(response?.data?.data?.attributes));
        // console.log(" SOLUTION DATA ", response?.data?.data?.attributes);
      })
      .catch((error: any) => {
        // console.log("ENV", ENV);
        console.log("error", error);
      });
  };

  

  return (
    <div>
      <Header activePage="solution" />
      <SolutionFace SolutionPageFace={SolutionData?.SolutionPageFace} />
      <DeploymentSection SolutionPageIntro={SolutionData?.SolutionPageIntro} />
      <HoolvaUnique HoolvaUnique={SolutionData?.HoolvaUnique} />
      {/* <SolutionCaseStudies /> */}
      <CspsSolutions HoolvaComparision={SolutionData?.HoolvaComparision} />
      <DeploymentOptions DeploymentOptions={SolutionData?.DeploymentOptions} />
      <HoolvaStructure HoolvaStructure={SolutionData?.HoolvaStructure} />
      <Footer />
    </div>
  );
}

export default SolutionPage;
