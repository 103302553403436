import React from "react";
import {
  ComponentIcon,
  FinishFlagIcon,
  PartnershipIcon,
  SharpHandshakeIcon,
  SimpleUploadIcon,
} from "./SvgIcons";

function SingleRoadMapCard(props: any) {
  const { data } = props;
  return (
    <div className=" py-3 lg:py-6 px-1 mx-1 lg:w-1/4 flex justify-center " data-aos="zoom-in">
      <div className=" bg-white w-full xl:w-[260px] h-full relative flex flex-col justify-center p-3 rounded-2xl items-center shadow-md ">
        <div className="w-[40px] h-[40px] absolute top-[-20px] mx-auto bg-gradient-to-b from-[#F65E1D] to-[#747474] rounded-xl text-center flex  justify-center  text-white text-xl font-bold items-center">
          {data?.Number}
        </div>
        <div className=" p-1 my-2 flex flex-col  justify-center">
          <div className=" p-2 my-2 w-[60px] h-[60px] justify-center mx-auto flex">
            {data.IconType === "component" ? (
              <ComponentIcon />
            ) : data.IconType === "sharphandshake" ? (
              <SharpHandshakeIcon />
            ) : data.IconType === "finishflag" ? (
              <FinishFlagIcon />
            ) : data.IconType === "simpleupload" ? (
              <SimpleUploadIcon />
            ) : null}
          </div>
          <div className="font-thin text-center p-2 text-sx md:text-sm lg:text-md">
            {data?.SingleSentence}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleRoadMapCard;
