import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import SingleCareersCard from "../Components/SingleCareersCard";
import env from "../config";
import * as Actions from "../redux/actions";

function CareersPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();

  // const ENV: any = useSelector(
  //   (state: any) => state?.Environment?.EnvironmentType
  // );

  const CareersData: any = useSelector(
    (state: any) => state?.CareersPage?.CareersPageContent
  );

  useEffect(() => {
    if (Object.keys(CareersData).length === 0) {
      getCareersPageData();
    }
  }, []);

  const getCareersPageData = async () => {
    await axios
      .get(`${env}/api/careers-page-content?populate=deep`)
      .then((response: any) => {
        dispatch(Actions.CareersPageAction(response?.data?.data?.attributes));
        // console.log(" CARRERS DATA ", response?.data?.data?.attributes);
      })
      .catch((error: any) => {
        // console.log("ENV", ENV);
        console.log("error", error);
      });
  };

  const ProjectsListData: any = useSelector(
    (state: any) => state?.PositionList?.PositionsListContent
  );

  useEffect(() => {
    if (ProjectsListData.length === 0) {
      getProjectsListData();
    }
    // getProjectsListData();
  }, []);

  const getProjectsListData = async () => {
    await axios
      .get(`${env}/api/positions-lists?populate=deep`)
      .then((response: any) => {
        dispatch(Actions.PositionListAction(response?.data?.data));
        // console.log(" PROJECTSLIST DATA ", response?.data?.data);
      })
      .catch((error: any) => {
        // console.log("ENV", ENV);
        console.log("error", error);
      });
  };

  return (
    <div>
      <Header activePage="careers" />
      <div
        style={{
          backgroundImage: `url(${env}${CareersData?.TitleBackgroundimage?.data?.attributes?.url})`,
          // width: "100%",
          // height: "685px",
          
          backgroundRepeat: "no-repeat",
          backgroundSize:"cover",
        }}
        className="w-auto xl:h-[667px] md:h-[485px] sm:h-[285px] h-[185px] pt-12 xl:pt-0 xl:pb-10 items-center"
      >
        <div className=" text-white flex h-full xl:h-screen items-center justify-center place-content-center ">
          <div
            className="xl:w-5/12 content-cent  p-2 content-center	text-center "
            data-aos="fade-down"
          >
            <div className=" text-3xl lg:text-4xl xl:text-5xl font-bold font-body">
              {CareersData?.FaceTitle}
            </div>
            <div className="font-normal hidden sm:flex	text-base  w-full md:w-11/12 md:ml-7 font-body ">
              {CareersData?.FaceDescription}
            </div>
          </div>
        </div>
      </div>
      {/* -------------------------------------------------------------- */}
      <div className=" bg-[#F4F4F4] py-5 lg:py-10 w-full">
        <div className="p-3 mx-1 md:mx-24 my-10 ">
          <div data-aos="fade-in">
            <div className=" flex items-center md:justify-start justify-center ">
              <hr className="border-orange-600 w-20 mb-4 border-[1.5px] bg-orange-600" />
            </div>
            <div className=" flex items-center justify-center text-center md:justify-start text-sm md:text-md xl:text-xl ">
              <div className={`font-body text-paleblack`}>
                {CareersData?.CareersIntro?.Tagline}
              </div>
            </div>
            <div className="flex items-center justify-center md:justify-start ">
              <div className="w-5/12 content-center  mt-3">
                <div className={` text-3xl lg:text-4xl xl:text-5xl font-bold text-center md:text-left font-body text-paleblack`}>
                  {CareersData?.CareersIntro?.Title}
                </div>
              </div>
            </div>
            <div className=" flex items-center justify-start">
              <div
                className={`w-719 font-body content-center mt-3 text-center	md:text-left text-paleblack text-sm md:text-md xl:text-xl`}
              >
                {CareersData?.CareersIntro?.Description}
              </div>
            </div>
          </div>
        </div>
        <div className="xl:mx-24 md:mx-20 md:my-4 p-2 ">
          <div className="w-full mx-auto xl:grid-cols-4 xl:grid  md:justify-center sm:flex sm:flex-col md:grid md:grid-cols-2 lg:grid lg:grid-cols-3  justify-evenly">
            {ProjectsListData.map((node: any) => (
              <SingleCareersCard
                key={node.id}
                SingleCareerCardData={node?.attributes}
                cardID={node?.id}
              />
            ))}
          </div>
        </div>
      </div>

      {/* -------------------------------------------------------------- */}

      <Footer />
    </div>
  );
}

export default CareersPage;
