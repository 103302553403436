import React from "react";
import env from "../config";
import Titles from "./Titles";

function DeploymentSection(props: any) {
  const { SolutionPageIntro } = props;
  return (
    <div className="pt-20 pb-12 bg-white">
      <Titles
        color="paleblack"
        small_heading={SolutionPageIntro?.Tagline}
        title={SolutionPageIntro?.Title}
      />
      <div className=" flex flex-col md:flex-row w-full xl:mt-16 justify-center xl:p-10 px-5 pb-3  md:item-center  ">
        <div className="flex justify-center p-2 xl:h-[400px] xl:w-[566px]  md:h-[400px] md:w-[466px]  w-full " data-aos="fade-right">
          <img
            src={`${env}${SolutionPageIntro?.Image?.data?.attributes?.url}`}
            alt="laptop"

          />
        </div>
        <div
          className="flex flex-row xl:mt-4 text-paleblack w-full justify-items-center items-center font-body font-light text-sm md:text-sm lg:text-md xl:text-xl  mx-2 md:w-484 p-3"
          data-aos="fade-up"
        >
          <div className="font-light">
            {SolutionPageIntro?.Description1}
            <br />
            <br />
            {SolutionPageIntro?.Description2}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeploymentSection;
