import React from "react";
import { Link } from "react-router-dom";

function SDKGetStarted() {
  return (
    <div className="bg-[#FDE9D2] py-5 md:py-16 xl:py-20">
      <div className="  md:w-[60%] lg:w-[40%] xl:w-[30%] p-1 mx-auto flex flex-col px-10">
        <div className="text-xl md:text-2xl lg:text-4xl xl:text-4xl text-paleblack font-bold mx-auto md:mx-0" data-aos="fade-down">
          Get Started Today
        </div>
        <div
          className="flex flex-col sm:flex-row p-1 w-full sm:w-[60%] md:w-full mx-auto md:mx-0  justify-between my-5 items-center "
          data-aos="zoom-in"
        >
          <div className="font-body font-light text-paleblack">Get API key and start in minutes.</div>
          <a
            href="https://developer.hoolva.com/"
            target="_blank"
            rel="noreferrer"
          >
            <button className="bg-gradient-to-r from-orange-400 to-orange-600 text-paleblack  mt-5  sm:mt-0 font-body font-medium text-sm md:text-md  py-1 px-2 lg:py-2 lg:px-4 border border-orange-600 hover:border-transparent rounded">
              <Link to="/developer-query">Sign Up</Link>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default SDKGetStarted;
