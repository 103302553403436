import React from "react";
import { Link } from "react-router-dom";

function SingleCareersCard(props: any) {
  const { SingleCareerCardData, cardID } = props;
  // console.log("Single card", SingleCareerCardData);

  function decodeURL(url: string) {
    url = url.replaceAll(" ", "-");
    return url;
  }

  return (
    <div
      className="xs:w-[95%] text-paleblack md:w-[270px] xl:max-w-[270px] xl:h-[240px] xl:max-h-[240px] bg-white p-4 rounded-[10px] flex flex-col m-[4px] border-[1px] border-[#DFDFDF]"
    // data-aos="zoom-in"
    >
      <div className="p-1  font-bold text-sm md:text-md xl:text-lg max-w-[100%] lg:h-[60px]  text-ellipsis text-[#404041x] truncate">
        {SingleCareerCardData?.PositionName}
      </div>
      <div className="multipleLineEllipsis p-1  text-sm md:text-md xl:text-md flex-1 max-w-[100%] max-h-[60px]">
        {SingleCareerCardData?.JobDescriptionText}
      </div>
      <div className="p-2 lg:p-4  text-center border-t-2 border-t-1 text-sm md:text-md xl:text-lg border-gray-200  text-[#3766ac] mt-2 lg:mt-3  font-regular hover:text-[#4B8AE9] ">
        <Link
          style={{ textDecoration: "none" }}
          to={`/single-career-page?c=${decodeURL(
            SingleCareerCardData?.PositionName
          )}&jId=${window.btoa(cardID)}`}
        >
          View more
        </Link>
      </div>
    </div>
  );
}

export default SingleCareersCard;
