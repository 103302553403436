import { combineReducers } from "redux";
import { PartnershipPageAction } from "./actions";
import {
  HomePageReducer,
  LogoReducer,
  SDKPageReducer,
  SolutionPageReducer,
  ContactPageReducer,
  AboutPageReducer,
  EnvironmentReducer,
  FooterReducer,
  SystemStatusPageReducer,
  PositionListReducer,
  CareersPageReducer,
  SupportPageReducer,
  PartnershipPageReducer,
  CaseStudyPageReducer,
  CaseStudyListReducer,
} from "./reducers";

const rootReducer = combineReducers({
  HomePage: HomePageReducer,
  ContactPage: ContactPageReducer,
  AboutPage: AboutPageReducer,
  SolutionPage: SolutionPageReducer,
  SDKPage: SDKPageReducer,
  Logo: LogoReducer,
  Environment: EnvironmentReducer,
  Footer: FooterReducer,
  SystemStatusPage: SystemStatusPageReducer,
  PositionList: PositionListReducer,
  CareersPage: CareersPageReducer,
  SupportPage: SupportPageReducer,
  PartnershipPage: PartnershipPageReducer,
  CaseStudyPage:CaseStudyPageReducer,
  CaseStudyList:CaseStudyListReducer,
});

export default rootReducer;
