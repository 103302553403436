import React from "react";
import Titles from "./Titles";
import Button2 from "./Button2";
import { Link } from "react-router-dom";

function HoolvaTeam(props: any) {
  const { HoolvaTeam } = props;
  return (
    <div className="bg-background-grey pt-20 pb-16 text-darkgrey">
      <Titles
        color="paleblack"
        small_heading={HoolvaTeam?.Content?.Tagline}
        title={HoolvaTeam?.Content?.Title}
        sub_heading={HoolvaTeam?.Content?.Description}
      />

      <div className="flex justify-center mt-12" data-aos="zoom-in">
        <Button2 buttontext={"Learn More"} link="/about" />
      </div>
    </div>
  );
}

export default HoolvaTeam;
