import React from "react";

function Table(props: any) {
  const { PricingTable } = props;
  return (
    <div className=" w-[90%] md:w-10/12">
      <table
        className="border-collapse w-full md:w-[80%] border-slate-400  mx-auto"
        data-aos="zoom-down"
      >
        <thead className="text-left h-12 font-semibold text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
          <tr>
            <th className="border border-x-transparent border-y-0 font-body text-white">
              Category
            </th>
            <th className="border border-x-transparent border-y-0 font-body text-white">
              Video Conferencing
            </th>
            <th className="border border-x-transparent border-y-0 font-body text-white">
              Audio Conferencing
            </th>
          </tr>
        </thead>
        <tbody className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl font-normal">
          <tr className="h-16  border-x-transparent" data-aos="fade-down">
            <td className="border border-x-transparent w-[30%] font-body text-white">
              {PricingTable?.Category1}
            </td>
            <td className="border border-x-transparent w-[30%] font-body text-white">
              {PricingTable?.Category1VCperUserMinPrice}/user min
            </td>
            <td className="border border-x-transparent w-[30%] font-body text-white">
              {PricingTable?.Category1ACperUserMinPrice}/user min
            </td>
          </tr>
          <tr className="h-16 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl" data-aos="fade-down">
            <td className="border border-x-transparent w-[30%] font-body text-white">
              {PricingTable?.Category2}
            </td>
            <td className="border border-x-transparent w-[30%] font-body text-white">
              {PricingTable?.Category2VCperMinPrice}/min
            </td>
            <td className="border border-x-transparent w-[30%] font-body text-white">
              {PricingTable?.Category2ACperMinPrice}/min
            </td>
          </tr>
          <tr className="h-16 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl " data-aos="fade-down">
            <td className="border border-x-transparent font-body border-y-0 w-[30%] text-white">
              {PricingTable?.Category3}
            </td>
            <td className="border border-x-transparent  font-body border-y-0 w-[30%] text-white">
              {PricingTable?.Category3VCperMinPrice}/min
            </td>
            <td className="border border-x-transparent font-body border-y-0 w-[30%] text-white">
              {PricingTable?.Category3ACperMinPrice}/min
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Table;
