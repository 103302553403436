import React from "react";
import { useSelector } from "react-redux";
import env from "../config";
import background from "../resourses/homeimage.png";
import Button1 from "./Button1";
import Button2 from "./Button2";

function HomeFace(props: any) {
  // const ENV: any = useSelector(
  //   (state: any) => state?.Environment?.EnvironmentType
  // );
  const { HomeFaceData } = props;
  // console.log("HomeFaceData", HomeFaceData);
  return (
    <div>
      <div
        className="w-auto xl:h-[870px] md:h-[485px]  lg:h-[385] sm:h-[285px] h-max pt-10 xl:pt-0"
        style={{
          backgroundImage: `url(${env}${HomeFaceData?.HomePageFaceBackgroundMedia?.data?.attributes?.url})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          data-aos="fade-down"
          className=" text-white flex h-full items-center justify-center place-content-center"
        >
          <div className="2xl:w-5/12 xl:w-7/12  md:10/12 w-full mx-auto text-center flex flex-col gap-3 mt-5 md:mt-0">
            <img src={`${env}${HomeFaceData?.image?.data?.attributes?.url}`} className="mx-auto max-w-prose" />
            <div className="text-xl md:text-2xl lg:text-4xl xl:text-5xl md:my-7 font-bold font-body">
              {HomeFaceData?.HomePageFaceTitle2}
            </div>
            <div className="text-xl md:text-2xl lg:text-4xl xl:text-5xl font-bold font-body">
              {HomeFaceData?.HomePageFaceTitle}
            </div>
            <div className="font-normal text-sm	xl:text-base p-8 w-11/12 xl:ml-7 font-body hidden sm:flex">
              {HomeFaceData?.HomePageFaceDescription}
            </div>
            {/* <div className="flex flex-row justify-center"> */}
            {/* <Button1 buttontext={"Button"} /> */}
            {/* <Button2 buttontext={"Get started for free"} /> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeFace;
