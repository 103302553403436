import React from "react";
import {
  CubeIcon,
  WhiteLabelingIcon,
  DashboardIcon,
  GovtIcon,
  SupportIcon,
} from "./SvgIcons";

function SingleWhyhoolvaCard(props: any) {
  const { singleCardData } = props;
  return (
    <div className="flex flex-row justify-center mx-1 hover:shadow-xl cursor-pointer bg-[#fcfcfc] hover:bg-white rounded-lg">
      <div className=" p-3 lg:p-4 xl:p-6 w-full  md:h-[220px] lg:w-[240px] lg:h-[220px] xl:w-[280px] xl:h-[260px]  rounded-lg border  ">
        <div className=" hidden sm:flex flex-row w-auto ">
          {singleCardData?.IconType === "cube" ? (
            <CubeIcon />
          ) : singleCardData?.IconType === "whitelabel" ? (
            <WhiteLabelingIcon />
          ) : singleCardData?.IconType === "dashboard" ? (
            <DashboardIcon />
          ) : singleCardData?.IconType === "support" ? (
            <SupportIcon />
          ) : null}
        </div>
        <h5 className="text-sm md:text-md lg:text-md xl:text-lg font-bold font-body tracking-tight text-paleblack dark:text-white py-2">
          {/* {singleCardData?.title} */}
          {singleCardData?.Title}
        </h5>
        <p className=" font-normal text-paleblack text-sm md:text-md lg:text-md xl:text-lg	 dark:text-gray-400 font-body">
          {/* {singleCardData?.description} */}
          {singleCardData?.Description}
        </p>
      </div>
    </div>
  );
}

export default SingleWhyhoolvaCard;
