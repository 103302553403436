import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button1 from "../Components/Button1";
import Button2 from "../Components/Button2";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import PartnershipFace from "../Components/PartnershipFace";
import PartnershipResgister from "../Components/PartnershipResgister";
import PartnershipRoadMap from "../Components/PartnershipRoadMap";
import PartnershipVerticals from "../Components/PartnershipVerticals";
import SingleRoadMapCard from "../Components/SingleRoadMapCard";
import {
  CodeIcon,
  ConfrenceIcon,
  HeadPhonePersonBigIcon,
  PartnershipIcon,
  SupportIcon,
} from "../Components/SvgIcons";
import Titles from "../Components/Titles";
import WhyPartner from "../Components/WhyPartner";
import env from "../config";
import * as Actions from "../redux/actions";

function PartnershipPage() {
  const dispatch = useDispatch();

  // const ENV: any = useSelector(
  //   (state: any) => state?.Environment?.EnvironmentType
  // );

  const PartnershipData: any = useSelector(
    (state: any) => state?.PartnershipPage?.PartnershipPageContent
  );

  useEffect(() => {
    if (Object.keys(PartnershipData).length === 0) {
      getPartnershipPageData();
    }
    window.scrollTo(0, 0);
  }, []);

  const getPartnershipPageData = async () => {
    await axios
      .get(`${env}/api/partnership-page-content?populate=deep`)
      .then((response: any) => {
        dispatch(
          Actions.PartnershipPageAction(response?.data?.data?.attributes)
        );
        // console.log(" PARTNERSHIP DATA ", response?.data?.data?.attributes);
      })
      .catch((error: any) => {
        // console.log("ENV", ENV);
        console.log("error", error);
      });
  };

  
  return (
    <div>
      <Header activePage="partnership" />

      <PartnershipFace PartnershipPageFaceContent={PartnershipData} />

      <WhyPartner PartnershipIntro={PartnershipData.PartnershipIntro} />

      <PartnershipVerticals
        PartnershipVerticals={PartnershipData.PartnershipVerticals}
      />

      <PartnershipRoadMap
        PartnershipRoadmap={PartnershipData?.PartnershipRoadmap}
      />

      <PartnershipResgister
        JoinPartnership={PartnershipData?.JoinPartnership}
      />

      <Footer />
    </div>
  );
}

export default PartnershipPage;
