import { useSelector } from "react-redux";
import env from "../config";

function SolutionFace(props: any) {
  const { SolutionPageFace } = props;

  // const ENV: any = useSelector(
  //   (state: any) => state?.Environment?.EnvironmentType
  // );

  // console.log("SOLUUU", SolutionPageFace);
  return (
    <div
     className="w-auto xl:h-[685px] md:h-[485px] sm:h-[285px] h-[185px] pt-10 xl:pt-0"
      style={{
        backgroundImage: `url(${env}${SolutionPageFace?.SolutionFaceBackgroundMedia?.data?.attributes?.url})`,
       
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    > 
      <div className=" text-white flex h-full items-center justify-center place-content-center  ">
        <div className="2xl:w-5/12 xl:w-7/12  md:10/12 w-full mx-auto text-center mt-5 md:mt-0 " data-aos="fade-down">
          <div className=" text-xl md:text-2xl lg:text-4xl xl:text-5xl font-bold font-body">
            {SolutionPageFace?.SolutionFaceTitle}
          </div>
          <div className="font-normal	text-base p-8 w-11/12 xl:ml-7 font-body hidden sm:flex">
            {SolutionPageFace?.SolutionFaceDescription}
          </div>
          <div className="flex flex-row justify-center">
            {/* <Button1 buttontext={"Button"} /> */}
            {/* <Button2 buttontext={"Get started for free"} /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SolutionFace;
