import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import React, { useState } from "react";
import SDKFeatures from "./SDKFeatures";
import SingleSDKFeatureCard from "./SingleSDKFeatureCard";
import SingleUseCaseCard from "./SingleUseCaseCard";
import SingleUseCaseCardMobileView from "./SingleUseCaseCardMobileView";
import { SliderBackwardArrowIcon, SliderForwardArrowIcon } from "./SvgIcons";

function SDKUseCases(props: any) {
  const { UseCases } = props;

  const [mouseInside, setMouseInside] = useState(true)
  const UseCasesData = [
    {
      type: "comm",
      title: "Communicators",
      description:
        "Build an enterprise-wide communication platform to facilitate real-time collaboration and teamwork among the  employees and deliver higher client satisfaction. Integrate SDKs to the existing enterprise application to achieve a pro communicator.",
    },
    {
      type: "webi",
      title: "Webinars",
      description:
        "Enable the vast use cases of webinars including product demo, event streaming, seminars and knowledge sharing, sales and promotions, Q&A sessions and virtual classrooms, with audio, video, chat and screen share features",
    },
    {
      type: "medical",
      title: "Medical Consulting",
      description:
        "Embed Video and chat features to existing health or hospital management tools for virtually  connecting patients across with medical practitioners. Advance global consultation with medical professionals across the world to solve unique diagnosis",
    },

    {
      type: "recruit",
      title: "Recruitment and Training",
      description:
        "Grow global teams by incorporating virtual interviews and selection processes to the conventional recruitment system. Bring about the change in personnels through knowledge sharing and trainings virtually",
    },
    {
      type: "custom",
      title: "Custom",
      description:
        "Create interactive interface to encourage collaboration and curiosity through HD video calls, in-meeting chats, screen sharing and hand raise for anything and everything you could think of.",
    },
  ];

  return (
    <div className="py-10 sm:py-20 w-full flex text-paleblack">
      <div className="w-[100%] sm:w-[90%] lg:w-11/12 bg-blue mx-auto flex flex-col ">
        <div
          className="text-xl md:text-2xl lg:text-4xl xl:text-5xl text-center lg:text-left font-bold py-1 my-2"
          data-aos="fade-down"
        >
          Use Cases
        </div>
        <div className="mx-auto my-10  lg:flex hidden flex-col w-full ">
          <CarouselProvider
            naturalSlideWidth={50}
            naturalSlideHeight={50}
            totalSlides={UseCases?.UseCaseCard.length}
            orientation={"horizontal"}
            infinite={true}
            visibleSlides={3.5}
            step={0.5}
            interval={2000}
            className="carousalContainer"
            isPlaying={mouseInside}
            dragEnabled={true}
            dragStep={1 / UseCases?.UseCaseCard.length}
          >
            <div
              className="h-full w-5/6 mx-auto rounded-md "
              data-aos="zoom-in"
            >
              <Slider>
                {UseCases?.UseCaseCard.map((node: any, i: any) => (
                  <Slide onMouseEnter={() => setMouseInside(mouseInside => false)} onMouseLeave={() => setMouseInside(mouseInside => true)} key={node.id} index={i}>
                    <SingleUseCaseCard singleCardData={node} />
                  </Slide>
                ))}
              </Slider>
            </div>
          </CarouselProvider>

          {/*--- Mobile View Slider --- */}

        </div>
        <div className="block lg:hidden">
          <CarouselProvider
            naturalSlideWidth={30}
            naturalSlideHeight={20}
            totalSlides={UseCases?.UseCaseCard.length}
            orientation={"horizontal"}
            infinite={true}
            step={0.5}
            interval={2000}
            visibleSlides={1}
            className="carousalContainer max-h-[320px] pt-5  flex flex-row item-center   pb-2 justify-center "
            isPlaying={false}
            dragEnabled={true}
            dragStep={1}
            touchEnabled
          >
            <ButtonBack className=" mt-[70px] h-10 text-paleblack ">
              <div className=" p-2 w-8 h-10 flex justify-center items-center  sliderbutton">
                <SliderBackwardArrowIcon />
              </div>
            </ButtonBack>
            <div
              className=" max-h-[220px] w-[90%] mx-auto rounded-md   p-1   "
            // data-aos="zoom-in"
            >
              <Slider className="max-h-[210px]   ">
                {UseCases?.UseCaseCard.map((node: any, i: any) => (
                  <Slide key={node.id} index={i}>
                    <SingleUseCaseCardMobileView
                      singleCardData={node} />
                  </Slide>
                ))}
              </Slider>
            </div>
            <ButtonNext className=" mt-[70px] h-10  ">
              <div className=" p-2 w-8 h-10 flex justify-center items-center  sliderbutton ">
                <SliderForwardArrowIcon />
              </div>
            </ButtonNext>
          </CarouselProvider>
        </div>
      </div>
    </div>
  );
}

export default SDKUseCases;
