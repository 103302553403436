import React from "react";
import Titles from "./Titles";

function CspsSolutions(props: any) {
  const { HoolvaComparision } = props;
  return (
    <div className="bg-[#404041] w-full py-10 xl:py-28 ">
      <Titles
        color="white"
        small_heading={HoolvaComparision?.TitleContent?.Tagline}
        title={HoolvaComparision?.TitleContent?.Title}
        sub_heading={HoolvaComparision?.TitleContent?.Description}
      />

      <div className=" mx-auto w-[95%] md:w-[80%]  2xl:w-[60%] ">
        <table className="border-collapse w-full border-slate-400 mt-16">
          <thead className="text-left h-12 font-semibold text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
            <tr>
              <th className="border border-x-transparent border-y-0 font-body text-white "></th>
              <th className="border border-x-transparent border-y-0 font-body text-white">
                Hoolva
              </th>
              <th className="border border-x-transparent border-y-0 font-body text-white">
                Zoom
              </th>
              <th className="border border-x-transparent border-y-0 font-body text-white">
                Amazon
              </th>
              <th className="border border-x-transparent border-y-0 font-body text-white">
                Twilio
              </th>
            </tr>
          </thead>
          <tbody className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl font-normal">
            <tr className="h-16 ">
              <th className="border border-x-transparent w-[280px] font-body text-white text-right pr-4">
                Branding
              </th>
              <td
                className="border border-x-transparent w-[280px] font-body text-white"
                data-aos="fade-right"
              >
                {HoolvaComparision?.HoolvaBrandingType}
              </td>
              <td
                className="border border-x-transparent w-[280px] font-body text-white"
                data-aos="fade-right"
              >
                {HoolvaComparision?.ZoomBrandingType}
              </td>
              <td
                className="border border-x-transparent w-[280px] font-body text-white"
                data-aos="fade-right"
              >
                {HoolvaComparision?.AmazonBrandingType}
              </td>
              <td
                className="border border-x-transparent w-[280px] font-body text-white"
                data-aos="fade-right"
              >
                {HoolvaComparision?.TwilioBrandingType}
              </td>
            </tr>
            <tr className="h-16 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
              <th className="border border-x-transparent w-[280px] font-body text-white text-right pr-4">
                Time to Market
              </th>
              <td
                className="border border-x-transparent w-[280px] font-body text-white"
                data-aos="fade-right"
              >
                {HoolvaComparision?.HoolvaTimeToMarket}
              </td>
              <td
                className="border border-x-transparent w-[280px] font-body text-white"
                data-aos="fade-right"
              >
                {HoolvaComparision?.ZoomTimeToMarket}
              </td>
              <td
                className="border border-x-transparent w-[280px] font-body text-white"
                data-aos="fade-right"
              >
                {HoolvaComparision?.AmazonTimeToMarket}
              </td>
              <td
                className="border border-x-transparent w-[280px] font-body text-white"
                data-aos="fade-right"
              >
                {HoolvaComparision?.TwilioTimeToMarket}
              </td>
            </tr>
            <tr className="h-16 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl ">
              <th className="border border-x-transparent font-body border-y-0 w-[280px] text-white text-right pr-4">
                Max Users
              </th>
              <td
                className="border border-x-transparent font-body border-y-0 w-[280px] text-white"
                data-aos="fade-right"
              >
                {HoolvaComparision?.HoolvaMaxUsers}
              </td>
              <td
                className="border border-x-transparent font-body border-y-0 w-[280px] text-white"
                data-aos="fade-right"
              >
                {HoolvaComparision?.ZoomMaxUsers}
              </td>
              <td
                className="border border-x-transparent font-body border-y-0 w-[280px] text-white"
                data-aos="fade-right"
              >
                {HoolvaComparision?.AmazonMaxUsers}
              </td>
              <td
                className="border border-x-transparent font-body border-y-0 w-[280px] text-white"
                data-aos="fade-right"
              >
                {HoolvaComparision?.TwilioMaxUsers}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CspsSolutions;
