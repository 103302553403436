const initialState = {
  EnvironmentType: "",
  HomePageContent: {},
  AboutPageContent: {},
  ContactPageContent: {},
  SolutionPageContent: {},
  LogoContent: {},
  SDKPageContent: {},
  FooterContent: {},
  SystemStatusContent: {},
  CareersPageContent: {},
  PositionsListContent: [],
  SupportPageContent: {},
  PartnershipPageContent: {},
  CaseStudyPageContent:{},
  CaseStudyListContent:[]
};

export function EnvironmentReducer(state = initialState, action: any) {
  switch (action.type) {
    case "ENV":
      return { ...state, EnvironmentType: action?.payload };
    default:
      return state;
  }
}

export function HomePageReducer(state = initialState, action: any) {
  switch (action.type) {
    case "HOMEPAGE":
      return { ...state, HomePageContent: action?.payload };
    default:
      return state;
  }
}

export function ContactPageReducer(state = initialState, action: any) {
  switch (action.type) {
    case "CONTACTPAGE":
      return { ...state, ContactPageContent: action?.payload };
    default:
      return state;
  }
}

export function AboutPageReducer(state = initialState, action: any) {
  switch (action.type) {
    case "ABOUTPAGE":
      return { ...state, AboutPageContent: action?.payload };
    default:
      return state;
  }
}

export function SolutionPageReducer(state = initialState, action: any) {
  switch (action.type) {
    case "SOLUTIONPAGE":
      return { ...state, SolutionPageContent: action?.payload };
    default:
      return state;
  }
}

export function SDKPageReducer(state = initialState, action: any) {
  // console.log("222", action.type, action.payload);

  switch (action.type) {
    case "SDKPAGE":
      return { ...state, SDKPageContent: action?.payload };
    default:
      return state;
  }
}

export function LogoReducer(state = initialState, action: any) {
  switch (action.type) {
    case "LOGO":
      return { ...state, LogoContent: action?.payload };
    default:
      return state;
  }
}

export function FooterReducer(state = initialState, action: any) {
  switch (action.type) {
    case "FOOTER":
      return { ...state, FooterContent: action?.payload };
    default:
      return state;
  }
}

export function SystemStatusPageReducer(state = initialState, action: any) {
  switch (action.type) {
    case "SYSTEMSTATUS":
      return { ...state, SystemStatusContent: action?.payload };
    default:
      return state;
  }
}

export function CareersPageReducer(state = initialState, action: any) {
  switch (action.type) {
    case "CAREERS":
      return { ...state, CareersPageContent: action?.payload };
    default:
      return state;
  }
}

export function PositionListReducer(state = initialState, action: any) {
  switch (action.type) {
    case "POSITIONLIST":
      return { ...state, PositionsListContent: action?.payload };
    default:
      return state;
  }
}

export function SupportPageReducer(state = initialState, action: any) {
  switch (action.type) {
    case "SUPPORT":
      return { ...state, SupportPageContent: action?.payload };
    default:
      return state;
  }
}

export function PartnershipPageReducer(state = initialState, action: any) {
  switch (action.type) {
    case "PARTNERSHIP":
      return { ...state, PartnershipPageContent: action?.payload };
    default:
      return state;
  }
}

export function CaseStudyPageReducer(state = initialState, action: any) {
  switch (action.type) {
    case "CASESTUDYPAGE":
      return { ...state, CaseStudyPageContent: action?.payload };
    default:
      return state;
  }
}

export function CaseStudyListReducer(state = initialState, action: any) {
  switch (action.type) {
    case "CASESTUDYLIST":
      return { ...state, CaseStudyListContent: action?.payload };
    default:
      return state;
  }
}

