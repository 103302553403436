/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import env from "../config";

import Button2 from "./Button2";
import HeaderDropDown from "./HeaderDropDown";
import { BurgerCloseMenu, BurgerMenu, DropdownArrow } from "./SvgIcons";

function Header(props: any) {
  const [dropDownFlag, setDropDownFlag] = useState(false);
  const [burgerDropDownFlag, setBurgerDropDownFlag] = useState(false);
  const { activePage } = props;

  // const ENV: any = useSelector(
  //   (state: any) => state?.Environment?.EnvironmentType
  // );

  const LogoData: any = useSelector((state: any) => state?.Logo?.LogoContent);
  // setTimeout(() => {
  //   console.log("ENV", ENV);
  //   console.log("LOGO", LogoData);
  // }, 5000);

  return (
    <div>
      <div className="flex flex-row w-full h-12 md:h-16 fixed bg-white shadow-xl z-10  md:justify-start select-none">
        <div className="flex w-fit  float-left md:mx-14 items-center md:justify-between">
          <div className="md:p-1  flex flex-row float-left md:w-auto item-center  ">
            <div
              className="flex md:hidden left-0 items-center cursor-pointer"
              onClick={() => setBurgerDropDownFlag(!burgerDropDownFlag)}
            >
              {burgerDropDownFlag ? <BurgerCloseMenu /> : <BurgerMenu />}
            </div>
            <div className=" mx-auto  text-stone-600 md:p-4 flex  justify-center items-center md:mx-2  ">
              <Link to={"/"}>
                {/* <img src={"./Hoolva-logo.png"} alt="logo" /> */}
                <img
                  className=" h-[15px] md:h-auto"
                  src={`${env}${LogoData?.Logo?.data?.attributes?.url}`}
                  alt="logo"
                />
              </Link>
            </div>
          </div>
          <div className=" hidden md:flex md:justify-evenly md:p-3 md:w-5/6   cursor-pointer ">
            <div
              onClick={() => setDropDownFlag(true)}
              className="m-1 font-body  flex flex-row items-center hover:text-orange-700  justify-around mx-3"
              // className={`m-1 font-body hover:text-orange-700 flex flex-row items-center justify-around mx-3 ${
              //   activePage === "developers"
              //     ? "text-orange-700"
              //     : "text-paleblack"
              // }`}
              id={activePage === "developers" ? "header-gradient-text" : ""}
            >
              <div className="mx-2">Developers</div>
              <DropdownArrow />
            </div>
            <Link to="/solution">
              <div
                className={"m-1 font-body hover:text-orange-700  mx-3 "}
                id={activePage === "solution" ? "header-gradient-text" : ""}
              >
                Solution
              </div>
            </Link>
            <Link to="/about">
              <div
                className={"m-1 font-body hover:text-orange-700  mx-3 "}
                id={activePage === "about" ? "header-gradient-text" : ""}
              >
                About
              </div>
            </Link>
            <Link to="/partnership">
              <div
                className={"m-1 font-body hover:text-orange-700 mx-2"}
                id={activePage === "partnership" ? "header-gradient-text" : ""}
              >
                Hoolva Partnership
              </div>
            </Link>
          </div>
        </div>
        {/* <div className="flex w-fit right-0 justify-end items-center  ml-auto mr-24">
          <button className=" h-[60%] flex items-center mr-2 bg-transparent font-body hover:bg-orange-600 text-paleblack  font-medium hover:text-white py-2 px-4 border border-orange-600 hover:border-transparent rounded">
            Login
          </button>
          <Button2 buttontext={"Sign Up"} />
        </div> */}



      </div>

      {dropDownFlag ? (
        <HeaderDropDown
          dropDownFlag={dropDownFlag}
          setDropDownFlag={setDropDownFlag}
        />
      ) : null}

      {burgerDropDownFlag ? (
        <div className="bg-white md:hidden w-[300px]  text-left py-2 px-4 fixed left-2 top-14 z-10 rounded-md shadow-xl select-none">
          <div className="text-[16px] p-1  w-full  my-[2px] border-b-2 border-gray-200">
            <Link
              id={activePage === "developers" ? "header-gradient-text" : ""}
              to="/hoolva-sdk"
            >
              Hoolva SDK
            </Link>
          </div>
          <div className="text-[16px] p-1  w-full  my-[2px] border-b-2 border-gray-200">
            <a href="https://docs.hoolva.com">Documentation </a>
          </div>
          <div className="text-[16px] p-1  w-full  my-[2px] border-b-2 border-gray-200">
            <Link
              id={activePage === "solution" ? "header-gradient-text" : ""}
              to="/solution"
            >
              Solution
            </Link>
          </div>
          <div className="text-[16px] p-1  w-full  my-[2px] border-b-2 border-gray-200">
            <Link
              to="/about"
              id={activePage === "about" ? "header-gradient-text" : ""}
            >
              About
            </Link>
          </div>
          <div className="text-[16px] p-1  w-full my-[2px] ">
            <Link
              to="/partnership"
              id={activePage === "partnership" ? "header-gradient-text" : ""}
            >
              Hoolva Partnership
            </Link>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Header;
