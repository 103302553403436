import React from "react";
import Titles from "./Titles";

function DeploymentOptions(props: any) {
  const { DeploymentOptions } = props;
  return (
    <div className="w-full bg-[#F4F4F4] py-10 lg:py-20  flex-col  ">
      <Titles
        color="paleblack"
        small_heading={DeploymentOptions?.TitleContent?.Tagline}
        title={DeploymentOptions?.TitleContent?.Title}
        sub_heading={DeploymentOptions?.TitleContent?.description}
      />
      <div className="flex flex-row p-3  mt-1 md:mt-12">
        <div className="mx-auto  p-3 flex flex-col md:flex-row  lg:w-4/5 md:w-5/6   justify-center  mb-5 md:mb-0 text-sm lg:text-lg xl:text-xl">
          <div
            className="flex flex-col p-6 w-full md:w-[42%] hover:w[42.1%] bg-[#fcfcfc] rounded-xl mx-auto md:mx-2 border-[1px] mb-5 md:mb-0 border-[#DFDFDF] hover:shadow-xl hover:bg-white select-none"
          // data-aos="zoom-in"
          >
            <div className=" font-bold mb-2 text-[#404041]">Bolt On</div>
            <div className="w-[95%] text-[#404041] ">
              {DeploymentOptions?.BoltOnDescription1}
              <br />
              <br />
              {DeploymentOptions?.BoltOnDescription2}
            </div>
          </div>

          <div
            className="flex flex-col  p-6  w-full md:w-[42%] rounded-xl bg-[#fcfcfc] hover:bg-white mx-auto md:mx-2 border-[1px] border-[#DFDFDF] hover:shadow-xl select-none"
          // data-aos="zoom-in"
          >
            <div className="font-bold mb-2 text-[#404041]">
              Build On
            </div>
            <div className="w-[95%] text-[#404041] ">
              {DeploymentOptions?.BuiltOnDescription1}
              <br />
              <br />
              {DeploymentOptions?.BuiltOnDescription2}
            </div>
          </div>
        </div>

        <div></div>
      </div>
    </div>
  );
}

export default DeploymentOptions;
