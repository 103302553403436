import React from "react";
import SingleRoadMapCard from "./SingleRoadMapCard";
import Titles from "./Titles";

function PartnershipRoadMap(props: any) {
  const { PartnershipRoadmap } = props;
  return (
    <div className="bg-[#F4F4F4] w-full py-5 ">
      <div className="mx-auto  p-2 w-full flex flex-col justify-center ">
        <Titles
          color="paleblack"
          small_heading={PartnershipRoadmap?.TitleContent?.Tagline}
          title={PartnershipRoadmap?.TitleContent?.Title}
          sub_heading={PartnershipRoadmap?.TitleContent?.Description}
        />
        <div className=" p-3 my-10 flex flex-col lg:flex-row  xl:w-[75%] mx-auto lg:w-[90%] w-full ">
          {PartnershipRoadmap?.RoadmapCard.map((node: any) => (
            <SingleRoadMapCard key={node.id} data={node} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PartnershipRoadMap;
