import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import JobApplyForm from "../Components/JobApplyForm";
import PositionDetails from "../Components/PositionDetails";
import env from "../config";

function SingleCareerPage() {
  let location = useLocation();

  const [queryString, setQueryString] = useState(window.location.search);
  let sp = new URLSearchParams(queryString);
  const [cardId, setCardId] = useState<any>("");
  const [singlePositionCardData, setSinglePositionCardData] = useState<any>({});

  const getActivePositionData = async () => {
    sp = new URLSearchParams(location.search);
    setCardId(sp.get("jId"));
    const id: any = sp.get("jId");
    try {
      await axios
        .get(`${env}/api/positions-lists/${atob(id)}?populate=deep`)
        .then(async (response: any) => {
          // console.log("dataaaaaaa", response);
          setSinglePositionCardData(response?.data?.data?.attributes);
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    } catch (e) {
      console.log("axios error", e);
    }
  };

  // console.log("SINGLE POSITION CARD :", singlePositionCardData);

  useEffect(() => {
    getActivePositionData();
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header activePage="careers" />
      {Object.keys(singlePositionCardData).length !== 0 ? (
        <PositionDetails jobDetails={singlePositionCardData} />
      ) : (
        <></>
      )}
      <JobApplyForm jobName={singlePositionCardData?.PositionName} />
      <Footer />
    </div>
  );
}

export default SingleCareerPage;
