import React from "react";
import env from "../config";

function HoolvaUnique(props: any) {
  const { HoolvaUnique } = props;
  return (
    <div className="flex  flex-col sm:flex-row bg-pink xl:py-20 ">
      <div className="flex flex-col md:w-1/2 w-full  p-2 items-center ">
        <div className=" p-2 my-auto flex flex-col xl:justify-start">
          <div
            className=" flex items-center justify-start p-2 "
            data-aos="fade-in"
          >
            <hr className="border-orange-600 w-24  border-[1.5px]" />
          </div>
          <div className=" flex justify-start " data-aos="fade-in">
            <div className="font-body text-sm md:text-md lg:text-md xl:text-xl font-thin text-paleblack p-2">
              {HoolvaUnique?.Tagline}
            </div>
          </div>

          <div className=" xl:justify-start" data-aos="fade-in">
            <div className="w-11/12 text-left  p-2 ">
              <div className="text-xl md:text-2xl lg:text-4xl xl:text-5xl font-bold font-body text-paleblack">
                {HoolvaUnique?.Title}
              </div>
            </div>
          </div>

          <div
            className=" flex  justify-start text-left p-2"
            data-aos="fade-up"
          >
            <div className="xl:w-[500px] text-sm md:text-md lg:text-md  xl:text-xl p-2 font-body flex-col">
              <div>{HoolvaUnique?.Description}</div>
              <br />
              {/* <div>
                Deploy VCaaS and see results in 1-2 quarters – rapidly roll out
                additional applications and new capabilities.
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row p-4 xl:h-[525px] xl:w-[686px] sm:mx-auto md:h-[450px] lg:w-1/2  h-[350px] w-full"
        data-aos="fade-down"
      >
        <img
          src={`${env}${HoolvaUnique?.Image?.data?.attributes?.url}`}
          alt="HoolvaUniqueLaptop.png"

        />
      </div>
    </div>
  );
}

export default HoolvaUnique;
