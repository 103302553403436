import React from "react";
import Titles from "./Titles";

function HoolvaStructure(props: any) {
  const { HoolvaStructure } = props;

  return (
    <div className="w-full bg-white py-10 xl:py-20 text-paleblack  ">
      <Titles
        color="paleblack"
        small_heading={""}
        title={HoolvaStructure?.Title}
        sub_heading={HoolvaStructure?.Description}
      />

      <div className=" w-full 2xl:w-3/4 xl:w-4/5  p-2 flex flex-col mx-auto text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl mt-10">
        <div className=" flex flex-col md:flex-row ">
          <div
            className="flex flex-col bg-[#FDE9D2] md:w-3/4 m-1 rounded-md border-[1px] border-[#c7c7c7] hover:bg-[#fde4c7]"
            data-aos="fade-down"
          >
            <div className="p-3 border-b-2 border-[#DFDFDF]">
              <div className="text-center font-bold  md:pb-3">
                Hoolva App development portal
              </div>
            </div>
            <div className="flex flex-row pt-2 md:pt-5 pb-1 md:pb-3 justify-around md:justify-evenly md:w-5/6 w-full mx-auto ">
              <div>UI Designer</div>
              <div>Pre-built UI Container</div>
              <div>Reporting Designer</div>
            </div>
          </div>

          <div
            className="flex flex-col md:w-1/4 bg-[#FDE9D2] m-1 rounded-md border-[1px] border-[#c7c7c7] hover:bg-[#fde4c7]"
            data-aos="fade-down"
          >
            <div className="p-3 border-b-2 border-[#DFDFDF]">
              <div className="text-center font-bold md:pb-3">
                Hoolva App market place
              </div>
            </div>
            <div className="pt-2 md:pt-5 pb-1 md:pb-3 text-center ">Developer Ecosystem</div>
          </div>
        </div>
        {/* ----------------------------- */}
        <div
          className="flex flex-col bg-[#FDE9D2] m-1 rounded-md border-[1px] border-[#c7c7c7] hover:bg-[#fde4c7]"
          data-aos="fade-down"
        >
          <div className="p-3 border-b-2 border-[#DFDFDF]">
            <div className="text-center font-bold md:pb-3">
              Hoolva SDK Library / REST APIs
            </div>
          </div>
          <div className="flex flex-row pb-1 md:pb-3   w-4/5 md:w-3/6 mx-auto justify-evenly pt-2 md:pt-5">
            <div>Android</div>
            <div>iOS</div>
            <div>Windows</div>
            <div>Mac</div>
            <div>Linux</div>
          </div>
        </div>
        {/* ----------------------- */}
        <div
          className="flex flex-col bg-[#FDE9D2]  m-1 rounded-md border-[1px] border-[#c7c7c7] hover:bg-[#fde4c7]"
          data-aos="fade-down"
        >
          <div className="p-3 border-b-2 border-[#DFDFDF]">
            <div className="text-center font-bold md:pb-3">Hoolva Core</div>
          </div>
          <div className="flex flex-row pb-1 md:pb-3   w-full md:w-3/6  mx-auto justify-evenly pt-2 md:pt-5">
            <div>Voice</div>
            <div>HD Video</div>
            <div>Cloud Recording</div>
            <div>Messaging</div>
            <div>Analytics</div>
          </div>
        </div>
        {/* ------------------------------- */}
        <div className=" flex flex-row">
          <div
            className="flex flex-col bg-[#FDE9D2] w-1/2 m-1 rounded-md border-[1px] border-[#c7c7c7] hover:bg-[#fde4c7]"
            data-aos="fade-down"
          >
            <div className="p-3 border-b-2 border-[#DFDFDF]">
              <div className="text-center font-bold md:pb-3">Hoolva Core</div>
            </div>
            <div className="flex text-center flex-row pt-2 md:pt-5 pb-1 md:pb-3 justify-evenly">
              <div>Billing, Monitoring, Logging, Auto Scaling</div>
            </div>
          </div>

          <div
            className="flex flex-col w-1/2 bg-[#FDE9D2] m-1 rounded-md border-[1px] border-[#c7c7c7] hover:bg-[#fde4c7]"
            data-aos="fade-down"
          >
            <div className="p-3 border-b-2 border-[#DFDFDF]">
              <div className="text-center font-bold md:pb-3">Security Layer</div>
            </div>
            <div className="pt-2 md:pt-5 pb-1 md:pb-3 text-center ">
              SSO, Role-Based access, Encryption, End to end data security
            </div>
          </div>
        </div>
        {/* ------------- */}
      </div>
    </div>
  );
}

export default HoolvaStructure;
