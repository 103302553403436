import React from "react";
import Table from "./Table";
import Titles from "./Titles";

function Pricing(props: any) {
  const { Pricing } = props;
  return (
    <div className="bg-darkgrey pt-20 pb-14 ">
      {/* <div className=" flex items-center justify-center ">
        <hr className="border-orange-600 w-20 mb-4" />
      </div>
      <div className=" flex items-center justify-center">
        <div className="font-body text-white">Pay as you go</div>
      </div>
      <div className="flex items-center justify-center">
        <div className="w-5/12 content-center text-center mt-3">
          <div className="text-5xl font-bold font-body text-white">Pricing</div>
        </div>
      </div>
      <div className=" flex items-center justify-center">
        <div className="w-720 font-body content-center mt-3	text-center text-white">
          Hoolva’s pricing philosophy is very simple - pay as you go. No hidden
          charges and no prepayment.
        </div>
      </div> */}
      <Titles
        color="white"
        small_heading={Pricing?.TitleContent?.Tagline}
        title={Pricing?.TitleContent?.Title}
        sub_heading={Pricing?.TitleContent?.Description}
      />
      <div className="flex justify-center mt-14">
        <Table PricingTable={Pricing} />
      </div>
    </div>
  );
}

export default Pricing;
