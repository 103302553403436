import React from "react";
import { Link } from "react-router-dom";
import Button2 from "./Button2";

function Contact_us(props: any) {
  const { PartnerWithUsContent } = props;
  return (
    <div className=" justify-center items-center  py-10 flex ">
      <div className=" w-full xl:w-3/4  p-2 flex flex-col md:flex-row px-0 lg:px-5 xl:px-0 xl:justify-center items-center ">

      <div className="  flex justify-center w-full md:w-auto xl:my-14 xl:w-[443px] ">
        <div className=" flex flex-col justify-center  p-2  ">
          <div
            className="flex flex-col  text-center md:text-left p-2  xl:mt-11 "
            data-aos="fade-down"
          >
            <div className=" text-xl md:text-2xl lg:text-4xl xl:text-5xl font-bold my-1 text-paleblack">
              {PartnerWithUsContent?.Title}
            </div>
            <div className="text-sm  xl:text-xl my-1 font-body text-paleblack">
              {PartnerWithUsContent?.Description}
            </div>
          </div>

          <div data-aos="zoom-in" className=" justify-center flex md:justify-start py-6 md:py-0">
            <Button2
              link="/contact-us"
              buttontext={PartnerWithUsContent?.ButtonText}
            />{" "}
          </div>
        </div>
      </div>
      <div className="md:ml-36 p-1 hidden md:flex xl:h-[300px]   " data-aos="fade-up">
        <img src={"./ContactUS.png"} alt="contact us" />
      </div>
      </div>
    </div>
  );
}

export default Contact_us;
