import React, { useState } from "react";
import { CommmunicatorBigIcon, CubeIcon } from "../Components/SvgIcons";
import ReactCardFlip from "react-card-flip";
import SingleDropDownCard from "../Components/SingleDropDownCard";

//import axios from "axios";

function HeaderDropDown(props: any) {
  const { setDropDownFlag, dropDownFlag } = props;
  return (
    <div
      className="w-[564px] h-[169px] p-1 fixed left-[180px] hidden  lg:left-[240px] shadow-xl top-[55px] bg-white z-20 rounded-lg rounded-t-none flex-col md:flex  border-t-0"
      onMouseLeave={() => setDropDownFlag(false)}
    >
      <div className="flex-row flex w-full h-full my-1">
        <SingleDropDownCard
          type={"cube"}
          link={"/hoolva-sdk"}
          title={"Hoolva SDK"}
          description={"The building blocks for your communication platform"}
        />
        <SingleDropDownCard
          type={"doc"}
          link={"https://docs.hoolva.com"}
          title={"Documentation"}
          description={"Extensive guide to quickly build your app"}
        />
      </div>
      {/* <div className="flex-row flex w-full h-1/2 my-1">
        <SingleDropDownCard
          type={"demo"}
          link={"/demo"}
          title={"Demo"}
          description={"request a live demonstration and hands-on trial"}
        />
        <SingleDropDownCard
          type={"api"}
          link={"/api-reference"}
          title={"API Reference"}
          description={"Your one stop manual to build with APIs"}
        />
      </div> */}
    </div>
  );
}

export default HeaderDropDown;
