import React from "react";
import {
  CurlyBracesIcon,
  DocumentIcon,
  HourGlassIcon,
  KeyIcon,
} from "./SvgIcons";

function DevNeedPoints(props: any) {
  const { data } = props;
  return (
    <div className=" flex flex-row w-full sm:w-[90%] " >
      <div className="hidden md:flex items-center justify-center p-1">
        {data?.IconType === "curlybraces" ? (
          <CurlyBracesIcon />
        ) : data?.IconType === "hourglass" ? (
          <HourGlassIcon />
        ) : data?.IconType === "writtendoc" ? (
          <DocumentIcon />
        ) : data?.IconType === "smallkey" ? (
          <KeyIcon />
        ) : null}
      </div>
      <div
        className=" flex w-[85%] p-2 font-body font-bold   text-sm md:text-sm lg:text-md xl:text-xl mx-auto  "
       
      >
        {data?.MenuTitle}
      </div>
    </div>
  );
}

export default DevNeedPoints;
