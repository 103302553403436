import React from "react";
import Titles from "./Titles";
import { Triangles1, Triangles2 } from "./SvgIcons";
import env from "../config";

function Marketneed(props: any) {
  const { HomePageIntro } = props;
  // console.log("HOMEINTRO", HomePageIntro);

  // const ENV: any = useSelector(
  //   (state: any) => state?.Environment?.EnvironmentType
  // );

  return (
    <div className=" pt-20 pb-12 overflow-x-hidden text-darkgrey relative">
      <Titles
        color="paleblack"
        small_heading={HomePageIntro?.TitleContent?.Tagline}
        title={HomePageIntro?.TitleContent?.Title}
      />
      <div className="  right-0 bottom-[250px] hidden 2xl:flex absolute" data-aos="slide-left">
        <Triangles1 />
      </div>
      <div className=" top-[50px] hidden 2xl:flex absolute" data-aos="slide-right">
        <Triangles2 />
      </div>
      <div className=" flex flex-col lg:flex-row w-full lg:mt-16 justify-center p-2 items-center overflow-y-hidden ">
        <div
          className="flex lg:w-fit justify-center p-2 overflow-y-hidden "
          data-aos="fade-down"

        >
          <img
            src={`${env}${HomePageIntro?.Image?.data?.attributes?.url}`}
            alt="laptop"
          />
        </div>
        <div className="flex flex-row mt-4 justify-items-start font-body text-darkgrey mx-2 w-full sm:w-[95%] md:w-[90%]  lg:w-2/5 text-sm md:text-md xl:text-xl  p-2">
          <div data-aos="fade-up">
            {HomePageIntro?.Description1}
            <br />
            <br />
            {HomePageIntro?.Description2}
            <div className="my-4  flex justify-center sm:justify-start ">
              {/* <Button2 buttontext={"Learn more"} /> */}
              <button
                className="bg-gradient-to-r  from-orange-400 to-orange-600 text-darkgrey  font-body text-xs md:text-md xl:text-xl py-1 lg:py-2 px-2 lg:px-4 border border-orange-600 hover:border-transparent rounded"
                onClick={() => window.scroll(0, 1450)}
              >
                {HomePageIntro?.HomePageButtonText}
              </button>

              {/* <a
                href="#WHYHOOLVA"
                className="bg-gradient-to-r from-orange-400 to-orange-600 text-black  font-body font-medium py-2 px-4 border border-orange-600 hover:border-transparent rounded"
              >
                {HomePageIntro?.HomePageButtonText}
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Marketneed;
