import React from "react";
import SingleWhyhoolvaCard from "./SingleWhyhoolvaCard";
import Titles from "./Titles";

function WhyHoolva(props: any) {
  const { WhyHoolva } = props;

  return (
    <div className="bg-background-grey pt-20 pb-20 text-darkgrey" id="WHYHOOLVA">
      <Titles

        small_heading={WhyHoolva?.WhyHoolvaTitle?.Tagline}
        title={WhyHoolva?.WhyHoolvaTitle?.Title}
        sub_heading={WhyHoolva?.WhyHoolvaTitle?.Description}
      />
      <div className=" p-3 mt-5 md:mt-10 xl:mt-14 flex flex-col md:flex-row justify-center  items-center">
        {/* {CardsData.map((node: any) => {
          return (
            <div data-aos="zoom-in" className="border-gray-200">
              <SingleWhyhoolvaCard singleCardData={node} />
            </div>
          );
        })} */}

        {WhyHoolva?.FeatureCard.map((node: any) => (
          <div key={node.id} data-aos="zoom-in" className="border-gray-200 w-full md:w-1/4 lg:w-auto my-1 md:my-0  ">
            <SingleWhyhoolvaCard singleCardData={node} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default WhyHoolva;
