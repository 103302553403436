import React, { useState } from "react";
import Titles from "./Titles";
import Button2 from "./Button2";
import { useRef } from "react";
import axios from "axios";
import env from "../config";

function ContactForm(props: any) {
  const { ContactData } = props;

  // console.log("CONTACT DTA", ContactData);
  const emptyState = {
    firstName: "",
    companyName: "",
    email: "",
    designation: "",
    message: "",
  };

  const [formData, setFormData] = useState(emptyState);
  const [flag, setFlag] = useState("initial");

  const handleForm = (e: any) => {
    const newFormData: any = { ...formData };
    newFormData[e.target.id] = e.target.value;
    setFormData(newFormData);
  };

  const sendMail = () => {
    // e.preventDefault();
    console.log(formData);
    if (
      formData.companyName === "" ||
      formData.firstName === "" ||
      formData.designation === "" ||
      formData.message === "" ||
      formData.email === ""
    ) {
      setFlag("notcomplete");
    } else {
      setFlag("loading");
      axios
        .post(`${env}/api/contact-emails`, {
          data: formData,
        })
        .then(() => {
          setFormData(emptyState);
          setFlag("success");
          setTimeout(() => {
            setFlag("initial");
          }, 10000);
        })
        .catch(() => {
          setFlag("error");
        });
    }
  };

  return (
    <div className="w-full pt-28 font-body pb-4 text-paleblack bg-[#F4F4F4] " data-aos="fade-down">
      <Titles
        color="paleblack"
        small_heading={ContactData?.TitleContent?.Tagline}
        title={ContactData?.TitleContent?.Title}
        sub_heading={ContactData?.TitleContent?.Description}
      />

      {/* {flag === "inital" && (
        <div className="flex flex-col bg-red-50 text-red-500 w-3/6 mx-auto p-3 border-red-500 border-2 rounded-md mt-20">
          Error
        </div>
      )} */}
      <div className="flex flex-col bg-white w-[95%] xl:w-5/6 2xl:w-[60%] mx-auto py-3 rounded-md my-10">
        {/* <form
          action="#"
          // onSubmit={(e) => sendMail(e)}
          // method="post"
          encType="text/plain"
        > */}

        <div className="flex flex-col xl:flex-row  px-3 justify-between">
          <div className="flex flex-col p-3  xl:w-1/2 mx-1 ">
            <label className="text-[#868686] md:text-[12px] text-[14px] lg:text-[16px]">First Name *</label>
            <input
              type="text"
              className="border-[1px] border-[#B0B0B0] rounded-md h-10 px-3 py-2 text-[14px] placeholder:text-[14px] placeholder:text-[#D7D7D7]"
              placeholder="First Name"
              id="firstName"
              value={formData.firstName}
              onChange={(e) => {
                handleForm(e);
                setFlag("initial");
              }}
              required
            />
          </div>
          <div className="flex flex-col  p-3 xl:w-1/2 mx-1">
            <label className="text-[#868686] md:text-[12px] text-[14px] lg:text-[16px]">Company Name *</label>
            <input
              required
              value={formData.companyName}
              className="border-[1px] border-[#B0B0B0] rounded-md h-10 px-3 py-2 text-14 placeholder:text-[14px] placeholder:text-[#D7D7D7]"
              placeholder="Company Name"
              type="text"
              id="companyName"
              onChange={(e) => {
                handleForm(e);
                setFlag("initial");
              }}
            />
          </div>
        </div>
        <div className="flex flex-col xl:flex-row  px-3 justify-between">
          <div className="flex flex-col p-3 xl:w-1/2 mx-1">
            <label className="text-[#868686] md:text-[12px] text-[14px] lg:text-[16px]">Email *</label>
            <input
              required
              value={formData.email}
              className="border-[1px] border-[#B0B0B0] rounded-md h-10 px-3 py-2 text-14 placeholder:text-[14px]  placeholder:text-[#D7D7D7]"
              placeholder="Email"
              type="email"
              id="email"
              onChange={(e) => {
                handleForm(e);
                setFlag("initial");
              }}
            />
          </div>
          <div className="flex flex-col p-3 xl:w-1/2 mx-1">
            <label className="text-[#868686] md:text-[12px] text-[14px] lg:text-[16px]">Designation *</label>
            <input
              required
              value={formData.designation}
              className="border-[1px] border-[#B0B0B0] rounded-md h-10 px-3 py-2 text-14 placeholder:text-[14px] placeholder:text-[#D7D7D7]"
              placeholder="Designation"
              type="text"
              id="designation"
              onChange={(e) => {
                handleForm(e);
                setFlag("initial");
              }}
            />
          </div>
        </div>
        <div className="px-4">
          <div className="flex flex-col p-3 ">
            <label htmlFor="" className="text-[#868686] md:text-[12px] text-[14px] lg:text-[16px]">
              Message *
            </label>
            <textarea
              required
              value={formData.message}
              name=""
              id="message"
              onChange={(e) => {
                handleForm(e);
                setFlag("initial");
              }}
              // cols={30}
              rows={4}
              placeholder="Jot down your query here..."
              className="border-[1px] resize-none border-[#B0B0B0] rounded-md  px-3 py-2 text-14 placeholder:text-[14px] placeholder:text-[#D7D7D7]"
            ></textarea>
          </div>
        </div>
        <div className="px-7 py-2 flex justify-center xl:justify-end ">
          <button
            // type="submit"
            // type="reset"

            type="button"
            disabled={
              flag === "loading"
                ? true
                : flag === "initial"
                  ? false
                  : flag === "notcomplete"
                    ? true
                    : false
            }
            onClick={() => sendMail()}
            style={{
              cursor:
                flag === "notcomplete" || flag === "loading"
                  ? "not-allowed"
                  : "pointer",
            }}
            className="bg-gradient-to-r from-orange-400 to-orange-600 text-black  font-body font-medium py-1 px-3 text-sm md:text-md lg:py-2 lg:px-4 border border-orange-600 hover:border-transparent rounded"
          >
            {flag === "loading"
              ? "Sending.."
              : flag === "initial"
                ? "Submit"
                : flag === "success"
                  ? "Submit"
                  : "Submit"}
          </button>
          {/* </form> */}
        </div>
        <div className="px-6">
          {flag === "success" && (
            <div

              className="flex flex-col bg-white text-[#89D074] w-full mx-auto p-2 md:p-3  border-[#89D074] border-2 rounded-md mt-2 font-semibold md:text-sm text-xs"
            >
              Success
              <div className=" py-1 md:py-2 text-[#89D074] font-normal">
                Your response was submitted
              </div>
            </div>
          )}
          {flag === "error" && (
            <div

              className="flex flex-col bg-white text-[#EE5F5F] w-full mx-auto  p-2 md:p-3  border-[#EE5F5F] border-2 rounded-md mt-2 font-semibold md:text-sm text-xs"
            >
              Error
              <div className=" py-1 md:py-2 text-[#EE5F5F] font-normal">
                There seems to be some error . Please try again
              </div>
            </div>
          )}
          {flag === "notcomplete" && (
            <div

              className="flex flex-col bg-white text-blue-500 w-full mx-auto p-2 md:p-3 border-blue-500 border-2 rounded-md mt-2  font-semibold md:text-sm text-xs"
            >
              Incomplete form
              <div className=" py-1 md:py-2 text-blue-500 font-normal">
                Please fill all fields to submit
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
