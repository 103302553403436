import React from "react";
import { Link } from "react-router-dom";
import { APIRefIcon, CopyIcon, CubeIcon, WrittenDocIcon } from "./SvgIcons";

function SingleDropDownCard(props: any) {
  const { link, title, description, type } = props;
  return type === "cube" ? (
    <Link to={link} className=" w-1/2 h-full mx-1">
      <div className=" flex flex-col bg-white w-full h-full p-2 rounded-md item-center justify-center border-2 border-[#DFDFDF] hover:border-2 hover:border-orange-600 cursor-pointer hover:bg-orange-50 transition ease-in-out delay-[0.1s]">
        <div className="flex flex-row p-2 items-center">
          <div>
            <CubeIcon />
          </div>
          <div className="mx-3 font-semibold">{title}</div>
        </div>
        <div className="w-auto  p-2">{description}</div>
      </div>
    </Link>
  ) : (
    <a
      href={link}
      target={type === "cube" ? "_self" : "_blank"}
      className=" w-1/2 h-full mx-1"
      rel="noreferrer"
    >
      <div className=" flex flex-col bg-white w-full h-full p-2 rounded-md item-center justify-center border-2 border-[#DFDFDF] hover:border-2 hover:border-orange-600 cursor-pointer hover:bg-orange-50 transition ease-in-out delay-[0.1s]">
        <div className="flex flex-row p-2 items-center">
          <div>
            {type === "doc" ? (
              <WrittenDocIcon />
            ) : type === "demo" ? (
              <CopyIcon />
            ) : type === "api" ? (
              <APIRefIcon />
            ) : null}
          </div>
          <div className="mx-3 font-semibold text-paleblack">{title}</div>
        </div>
        <div className="w-auto  p-2">{description}</div>
      </div>
    </a>
  );
}

export default SingleDropDownCard;
