import React from "react";
import { Link } from "react-router-dom";

function Button2(props: any) {
  return (
    <div>
      <Link to={`${props.link}`}>
        <button className="bg-gradient-to-r from-orange-400 to-orange-600 text-black  font-body xl:text-md lg:text-md  text-xs sm:text-sm py-1 lg:py-2 px-2 lg:px-4 border border-orange-600 hover:border-transparent rounded">
          {props.buttontext}
        </button>
      </Link>
    </div>
  );
}

export default Button2;
