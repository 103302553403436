import React, { useEffect } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import ContactForm from "../Components/ContactForm";
import ContactsBottomMenu from "../Components/ContactsBottomMenu";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../redux/actions";
import env from "../config";
import ContactMap from "../Components/ContactMap";

function ContactPage() {
  const dispatch = useDispatch();

  // const ENV: any = useSelector(
  //   (state: any) => state?.Environment?.EnvironmentType
  // );

  const ContactData: any = useSelector(
    (state: any) => state?.ContactPage?.ContactPageContent
  );

  useEffect(() => {
    if (Object.keys(ContactData).length === 0) {
      getContactPageData();
    }

    window.scrollTo(0, 0);
  }, []);

  const getContactPageData = async () => {
    await axios
      .get(`${env}/api/contact-page-content?populate=deep`)
      .then((response: any) => {
        dispatch(Actions.ContactPageAction(response?.data?.data?.attributes));
        // console.log(" CONTACT DATA ", response?.data?.data?.attributes);
      })
      .catch((error: any) => {
        // console.log("ENV", ENV);
        console.log("error", error);
      });
  };



  return (
    <div>
      <Header activePage="contact" />
      <br />
      <br />
      <ContactForm ContactData={ContactData} />
      <ContactsBottomMenu />
      <ContactMap/>
      <Footer />
    </div>
  );
}

export default ContactPage;
