import React from "react";
import env from "../config";

function PartnershipFace(props: any) {
  const { PartnershipPageFaceContent } = props;
  return (
    <div
      className="w-auto xl:h-[841px] md:h-[485px]  lg:h-[385] sm:h-[285px] h-[185px] pt-10 xl:pt-0"
      style={{
        backgroundImage: `url(${env}${PartnershipPageFaceContent?.FaceBackgroundImage?.data?.attributes?.url})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className=" text-white flex h-full items-center justify-center place-content-center">
        <div className="2xl:w-5/12 xl:w-7/12  md:10/12 w-full mx-auto text-center mt-5 md:mt-0" data-aos="fade-down">
          <div className="text-xl md:text-2xl lg:text-4xl xl:text-5xl font-bold font-body">
            {PartnershipPageFaceContent?.PageTitle}
          </div>
          <div className="font-normal	text-base p-8 w-11/12 xl:ml-7 font-body hidden sm:flex">
            {PartnershipPageFaceContent?.PageFaceDescription}
          </div>
          {/* <div className="flex flex-row justify-center">
            
            <button
              className={`mr-2 bg-gradient-to-r from-orange-400 to-orange-600 font-body  text-black  font-medium  py-2 px-4 border border-orange-600 hover:border-transparent rounded`}
            >
              Login
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default PartnershipFace;
