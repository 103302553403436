import React from "react";
import { ConfrenceIcon, PartnershipIcon, PartnershipIcon1, SupportIcon } from "./SvgIcons";

function WhyPartner(props: any) {
  const { PartnershipIntro } = props;
  return (
    <div className="w-full bg-[#FDE9D2] xl:py-10  " id="partnershipadvantages">
      <div className="xl:w-[80%]  mx-auto  p-2 flex flex-row justify-evenly xl:justify-between  ">
        <div className="  xl:w-[45%]  flex-col py-10 flex md:flex-row items-center justify-center w-full lg:justify-start ">

          <div className="flex flex-col  p-2 w-full  md:w-1/2 xl:w-full ">
            <div className=" p-2 my-auto flex flex-col justify-center lg:justify-start ">
              <div
                className=" flex items-center justify-center lg:justify-start p-2 "
                data-aos="fade-in"
              >
                <hr className="border-orange-600 w-24  border-[1.5px] bg-orange-600" />
              </div>
              <div className=" flex justify-start " data-aos="fade-in">
                <div className="font-body text-md xl:text-xl font-thin text-paleblack p-2">
                  {PartnershipIntro?.TitleContent?.Tagline}
                </div>
              </div>

              <div
                className="flex  justify-start"
                data-aos="fade-in"
                data-aos-anchor-placement="center"
              >
                <div className="w-11/12 text-left  p-2 ">
                  <div className="text-xl md:text-2xl lg:text-4xl xl:text-5xl font-bold font-body text-paleblack">
                    {PartnershipIntro?.TitleContent?.Title}
                  </div>
                </div>
              </div>

              <div
                className=" flex  justify-start text-left p-2"
                data-aos="fade-up"
              >
                <div className="w-full text-md xl:text-xl font-body p-2 font-light flex-col text-paleblack">
                  <div>{PartnershipIntro?.TitleContent?.Description}</div>
                </div>
              </div>
            </div>
          </div>

          <div className=" xl:hidden  md:w-1/2 flex md:flex-col sm:flex-row flex-col p-2 ">
            <div className="bg-white  my-1 flex flex-col p-4 md:p-4 mx-1 md:mx-0 md:w-auto w-auto sm:w-1/3 rounded-md shadow-md border-[1px] border-orange-500" data-aos="zoom-in">
              <div className="font-bold ">{PartnershipIntro?.stickyNote1?.noteTitle}</div>
              <div className=" text-sm md:text-md xl:text-xl">{PartnershipIntro?.stickyNote1?.noteDescription}</div>
            </div>
            <div className="bg-white my-1 flex flex-col p-4  md:p-4 mx-1 md:mx-0 md:w-auto w-auto sm:w-1/3 rounded-md shadow-md border-[1px] border-orange-500" data-aos="zoom-in">
              <div className="font-bold ">{PartnershipIntro?.stickyNote2?.noteTitle}</div>
              <div className="text-sm md:text-md xl:text-xl">{PartnershipIntro?.stickyNote2?.noteDescription}</div>
            </div>
            <div className="bg-white my-1 flex flex-col p-4  md:p-4 mx-1 md:mx-0 md:w-auto w-auto sm:w-1/3 rounded-md shadow-md border-[1px] border-orange-500" data-aos="zoom-in">
              <div className="font-bold ">{PartnershipIntro?.stickyNote3?.noteTitle}</div>
              <div className="text-sm md:text-md xl:text-xl">{PartnershipIntro?.stickyNote3?.noteDescription}</div>
            </div>


          </div>
        </div>

        <div className=" w-3/5 h-auto text-paleblack px-4 relative py-[80px] hidden  xl:flex">
          <div
            className=" partnerCircle w-[513px] h-[513px] bg-transparent  2xl:bg-black opacity-0  mx-auto rounded-full"
            data-aos="zoom-in"
          ></div>
          <div className="w-fit bg-black absolute top-[-100px]">
            <div className="note1 w-[225px] h-fit p-4 bg-white flex rounded-sm flex-col absolute top-[160px] left-[210px] rotate-[10.88deg] shadow-lg z-[7]">
              <div className=" w-full  px-1 py-3">
                <div className="p-2 bg-gray-200 rounded-md w-fit">
                  <PartnershipIcon1 />
                </div>
              </div>
              <div className="w-full  p-1 font-bold">
                {PartnershipIntro?.stickyNote1?.noteTitle}
              </div>
              <div className="w-full break-after-all  p-1 text-[16px]">
                {" "}
                {PartnershipIntro?.stickyNote1?.noteDescription}
              </div>
            </div>

            <div
              data-aos="zoom-in"
              className=" note2 w-[225px] h-fit p-4 bg-white flex rounded-sm flex-col absolute top-[210px] left-[440px] rotate-[0deg] z-[6] shadow-lg"
            >
              <div className=" w-full  px-1 py-3">
                <div className="p-2 bg-gray-200 rounded-md w-fit">
                  <SupportIcon />
                </div>
              </div>
              <div className="w-full  p-1 font-bold">
                {PartnershipIntro?.stickyNote2?.noteTitle}
              </div>
              <div className="w-full break-after-all  p-1 text-[16px]">
                {" "}
                {PartnershipIntro?.stickyNote2?.noteDescription}
              </div>
            </div>

            <div
              data-aos="zoom-in"
              className=" note3 w-[280px] h-fit p-4 bg-white rounded-sm flex flex-col absolute top-[510px] left-[320px] rotate-[0deg] z-[5]"
            >
              <div className=" w-fit rounded-md bg-gray-200 px-1 py-3">
                <ConfrenceIcon />
              </div>
              <div className="w-full  p-1 font-bold">
                {PartnershipIntro?.stickyNote3?.noteTitle}
              </div>
              <div className="w-full break-after-all  p-1 text-[16px]">
                {" "}
                {PartnershipIntro?.stickyNote3?.noteDescription}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyPartner;
