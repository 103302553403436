import React from "react";
import {
  CastingIcon,
  ConfrenceIcon,
  EducationIcon,
  ERPIcon,
  FinanceIcon,
  GovtIcon,
  HealthIcon,
  SupportIcon,
  StreamingIcon,
} from "./SvgIcons";

function SingleDemandCard(props: any) {
  const { singleCardData } = props;
  return (
    <div data-aos="zoom-in">
      <div className="p-6  w-[100px] sm:w-[150px] sm:h-[150px] h-[100px]   xl:w-[160px] xl:h-[160px] bg-[#fcfcfc] rounded-lg my-1 flex flex-col item-center hover:shadow-xl hover:bg-white">
        <div className="mx-auto w-5/6  mt-1 h-1/2 flex justify-center items-center scale-[0.6] sm:scale-[.80] md:scale-[0.90] lg:scale-100">
          {singleCardData.type === "govt" ? (
            <GovtIcon />
          ) : singleCardData.type === "health" ? (
            <HealthIcon />
          ) : singleCardData.type === "edu" ? (
            <EducationIcon />
          ) : singleCardData.type === "doller" ? (
            <FinanceIcon />
          ) : singleCardData.type === "erp" ? (
            <ERPIcon />
          ) : singleCardData.type === "sales" ? (
            <SupportIcon />
          ) : singleCardData.type === "cast" ? (
            <CastingIcon />
          ) : singleCardData.type === "stream" ? (
            <StreamingIcon />
          ) : singleCardData.type === "confrence" ? (
            <ConfrenceIcon />
          ) : null}
        </div>
        <div className=" mt-4 text-[10px] md:text-xs xl:text-md font-bold font-body tracking-tight text-paleblack text-center dark:text-white h-1/2  flex justify-center items-center">
          <h5 className="">{singleCardData?.text}</h5>
        </div>
      </div>
    </div>
  );
}

export default SingleDemandCard;
