import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Dummytest from "./Pages/Dummytest";
import DevelopersPage from "./Pages/SDKPage";
import SolutionPage from "./Pages/SolutionPage";
import PricingPage from "./Pages/PricingPage";
import PartnershipPage from "./Pages/PartnershipPage";
import CareersPage from "./Pages/CareersPage";
import ContactPage from "./Pages/ContactPage";
import AboutPage from "./Pages/AboutPage";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import SDKPage from "./Pages/SDKPage";
import * as Actions from "./redux/actions";
// import environment from "../environment";
import env from "./config";
// import { env } from "../../package.json";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SingleCareerPage from "./Pages/SingleCareerPage";
import SupportPage from "./Pages/SupportPage";
import SystemStatusPage from "./Pages/SystemStatusPage";
import DeveloperQueryPage from "./Pages/DeveloperQueryPage";
import CaseStudiesPage from "./Pages/CaseStudiesPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

function App() {
  const dispatch = useDispatch();

  const LogoData: any = useSelector((state: any) => state?.Logo?.LogoContent);

  const HomeData: any = useSelector(
    (state: any) => state?.HomePage?.HomePageContent
  );

  const getHomePageData = async () => {
    await axios
      .get(`${env}/api/home-page-content?populate=deep`)
      .then(async (response: any) => {
        dispatch(Actions.HomePageAction(response?.data?.data?.attributes));
        dispatch(
          Actions.FooterAction(response?.data?.data?.attributes?.FooterContent)
        );
        // console.log("DATA", response?.data?.data?.attributes?.FooterContent);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  };

  const getLogoData = async () => {
    await axios
      .get(`${env}/api/logo?populate=deep`)
      .then((response: any) => {
        dispatch(Actions.LogoAction(response?.data?.data?.attributes));
        // console.log("DATA", response);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    dispatch(Actions.EnvironmentAction(env));

    if (Object.keys(HomeData).length === 0) {
      getHomePageData();
    }

    if (Object.keys(LogoData).length === 0) {
      getLogoData();
    }
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      useClassNames: true,
      animatedClassName: "animated",
    });
    AOS.refresh();
    document.title = "Hoolva";
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/hoolva-sdk" element={<SDKPage />} />
          <Route path="/solution" element={<SolutionPage />} />
          <Route path="/partnership" element={<PartnershipPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/contact-us" element={<ContactPage />} />
          <Route path="/about" element={<AboutPage />} />
          {/* <Route path="/case-studies" element={<CaseStudiesPage/>}/> */}
          <Route path="/single-career-page" element={<SingleCareerPage />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/system-status" element={<SystemStatusPage />} />
          <Route path="/developer-query" element={<DeveloperQueryPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          {/* {/* <Route path="/pricing" element={<PricingPage />} /> */}
          {/* <Route path="/dummy" element={<Dummytest />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
