import React from "react";

function PositionDetails(props: any) {
  const { jobDetails } = props;
  return (
    <div className="w-full bg-[#F4F4F4] pt-20 lg:pt-40 md:pb-20 md:justify-center">
      <div className=" lg:mx-24 md:mx-14 mx-auto p-1 flex flex-col w-[95%] md:w-auto ">
        <div className=" p-1 my-1 text-3xl   lg:text-4xl  xl:text-5xl font-bold py-10" data-aos="fade-in">
          {jobDetails?.PositionName}
        </div>
        <div className=" p-1 my-1 font-semibold lg:text-xl md:text-md text-sm" data-aos="fade-in">
          Location :{" "}
          <span className="font-normal">{jobDetails?.LocationText}</span>
        </div>
        <div className=" p-1 my-1 font-semibold lg:text-xl md:text-md text-sm" data-aos="fade-in">
          Experience :{" "}
          <span className="font-normal">{jobDetails?.Experiancetext}</span>
        </div>
        <div className=" p-1 my-1 font-semibold lg:text-xl md:text-md text-sm" data-aos="fade-in">
          Salary: <span className="font-normal">{jobDetails?.SalaryText}</span>
        </div>
        <div className=" p-1 my-1 font-semibold lg:text-xl md:text-md text-sm" data-aos="fade-in">
          Job Description :{" "}
          <span className="font-normal">{jobDetails?.JobDescriptionText} </span>
        </div>

        {jobDetails?.Requirements?.length > 0 ? (
          <div
            className=" p-4 my-1 font-semibold lg:text-xl text-md border-[1px] border-gray-500 bg-gray-200 rounded-md"
            data-aos="zoom-in"
          >
            <div className=" p-1 my-1 font-bold"> Requirements</div>
            <ul className="font-normal  p-1 my-1 list-disc ml-5">
              {jobDetails?.Requirements.map((node: any) => (
                <li className="my-1 lg:text-xl md:text-md text-sm" key={node.id}>
                  {node?.RequirementText}
                </li>
              ))}
            </ul>
          </div>
        ) : null}

        {jobDetails?.Responsibilies.length > 0 ? (
          <div
            className=" p-4 my-1 font-semibold lg:text-xl border-[1px] border-gray-500 bg-gray-200 rounded-md"
            data-aos="zoom-in"
          >
            <div className="font-bold  p-1 my-1"> Responsibilities</div>
            <ul className="font-normal  p-1 my-1 list-disc ml-5 ">
              {jobDetails?.Responsibilies.map((node: any) => (
                <li className="my-1 lg:text-xl md:text-md text-sm" key={node.id}>
                  {node?.ResponsibilityText}
                </li>
              ))}
            </ul>
          </div>
        ) : null}

        <hr className="mt-20 border-1 border-gray-300" />
      </div>
    </div>
  );
}

export default PositionDetails;
