import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Dot,
  DotGroup,
  Slide,
  Slider,
} from "pure-react-carousel";
import React, { useEffect } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import SingleAboutCard from "../Components/SingleAboutCard";
import "pure-react-carousel/dist/react-carousel.es.css";
import {
  LinkedInSquareIcon,
  SliderBackwardArrowIcon,
  SliderForwardArrowIcon,
} from "../Components/SvgIcons";
import Titles from "../Components/Titles";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../redux/actions";
import env from "../config";

function AboutPage() {
  const dispatch = useDispatch();

  // const ENV: any = useSelector(
  //   (state: any) => state?.Environment?.EnvironmentType
  // );

  const AboutData: any = useSelector(
    (state: any) => state?.AboutPage?.AboutPageContent
  );

  useEffect(() => {
    if (Object.keys(AboutData).length === 0) {
      getAboutPageData();
    }
  }, []);

  const getAboutPageData = async () => {
    await axios
      .get(`${env}/api/about-page-content?populate=deep`)
      .then((response: any) => {
        dispatch(Actions.AboutPageAction(response?.data?.data?.attributes));
        // console.log(" ABOUT DATA ", response?.data?.data?.attributes);
      })
      .catch((error: any) => {
        // console.log("ENV", ENV);
        console.log("error", error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div>
      <Header activePage="about" />
      <br />
      <br />
      <div
        className="w-auto  md:hidden sm:h-[285px] h-[185px] text-paleblack pt-10 xl:pt-0"
        style={{
          backgroundImage: `url(${env}${AboutData?.AboutInformationContent?.Image?.data?.attributes?.url})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className=" text-white flex h-full items-center justify-center place-content-center">
          <div className="xl:w-5/12 content-cent text-center" data-aos="fade-down">
            <div className=" text-3xl lg:text-4xl xl:text-5xl font-bold font-body">
              About Us
            </div>
          </div>
        </div>
      </div>
      <div className="w-full py-20 bg-[#F4F4F4] text-paleblack border-2 font-body">
        <Titles
          color="[#404041]"
          small_heading={AboutData?.AboutPageIntro?.Tagline}
          title={AboutData?.AboutPageIntro?.Title}
          sub_heading={AboutData?.AboutPageIntro?.Description}
        />
      </div>
      <div className="w-full py-10 xl:py-28 bg-[#FDE9D2] text-paleblack">
        <div className="flex flex-row bg-pink  xl:py-20 justify-center">
          <div className="flex flex-col  p-2 items-center">
            <div className=" p-2 my-auto flex flex-col justify-start ">
              <div
                className=" flex items-center justify-center xl:justify-start p-2 "
                data-aos="fade-in"
              >
                <hr className="border-orange-600 w-24 border-[1.5px] bg-orange-600" />
              </div>
              <div className=" flex xl:justify-start justify-center  " data-aos="fade-in">
                <div className="font-body text-sm md:text-md xl:text-xl text-center xl:text-left  font-thin text-paleblack p-2">
                  {AboutData?.AboutInformationContent?.Tagline}
                </div>
              </div>

              <div
                className="flex  justify-start"
                data-aos="fade-in"
                data-aos-anchor-placement="center"
              >
                <div className="wf-full mx-auto xl:mx-0 xl:w-11/12 xl:text-left  text-center p-2 ">
                  <div className="text-3xl xl:text-5xl font-bold font-body text-[#404041]">
                    {AboutData?.AboutInformationContent?.Title}
                  </div>
                </div>
              </div>

              <div
                className=" flex  xl:justify-start xl:text-left font-body text-[#404041] text-center p-2"
                data-aos="fade-up"
              >
                <div className="xl:w-[500px] text-sm md:text-md xl:text-xl p-2 font-light flex-col">
                  <div>{AboutData?.AboutInformationContent?.Description}</div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="xl:flex flex-row p-4 hidden"
            data-aos="fade-left"
            data-aos-anchor-placement="top-center"
          >
            <img
              src={`${env}${AboutData?.AboutInformationContent?.Image?.data?.attributes?.url}`}
              alt="HoolvaUniqueLaptop.png"
              height={584}
              width={646}
            />
          </div>
        </div>
      </div>
      {/* <div className="mx-auto my-10 p-2 flex flex-col">
          <CarouselProvider
            naturalSlideWidth={90}
            naturalSlideHeight={20}
            totalSlides={aboutData.length}
            orientation={"horizontal"}
            infinite={false}
            className="carousalContainer"
            // isPlaying={true}
            dragEnabled={true}
            dragStep={1}
          >
            <div
              className="bg-white w-[60%] h-[260px] mx-auto p-2 shadow-xl rounded-md "
              data-aos="zoom-in"
            >
              <Slider>
                {aboutData.map((node: any, i: any) => (
                  <Slide index={1}>
                    <SingleAboutCard data={node} />
                  </Slide>
                ))}
              </Slider>
            </div>
            <div className=" w-1/2 mx-auto mt-10 py-2 px-6 flex flex-row justify-between">
              <ButtonBack>
                <div className="bg-white p-2 w-10 h-10 flextext-[#404041]  justify-center items-center rounded-md">
                  <SliderBackwardArrowIcon />
                </div>
              </ButtonBack>
              <DotGroup
                className="mt-2"
                showAsSelectedForCurrentSlideOnly={true}
              >
                {aboutData.map((node: any, index: any) => (
                  <Dot slide={index} key={index}>
                    <div className="w-3 h-3 rounded-xl bg-[#D9D9D9] mx-1"></div>
                  </Dot>
                ))}
              </DotGroup>
              <ButtonNext>
                <div className="bg-white p-2 w-10 h-10 flex justify-center items-center rounded-md">
                  <SliderForwardArrowIcon />
                </div>
              </ButtonNext>
            </div>
          </CarouselProvider>
        </div> */}

      <Footer />
    </div>
  );
}

export default AboutPage;
