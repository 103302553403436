import React from "react";

function SDKIntro(props: any) {
  const { SDKFaceContent } = props;
  return (
    <div className="w-full bg-white pt-5 lg:pt-28 text-paleblack lg:pb-40 p-1">
      <div className=" w-full md:w-[90%] lg:w-[85%] xl:w-[80%] flex flex-col-reverse md:flex-row  p-1 mx-auto justify-between ">
        <div
          className="lg:w-1/2 xl:w-1/2 2xl:w-2/5 flex flex-col px-1 py-5 md:py-10 justify-center md:justify-around  text-center md:text-left"
          data-aos="fade-up"
        >
          <div className=" text-xl md:text-2xl lg:text-4xl xl:text-5xl font-bold p-1  lg:leading-[60px] ">
            {SDKFaceContent?.Title}
          </div>
          <div className=" text-sm md:text-md lg:text-lg xl:text-xl text-light  p-1  ">
            {SDKFaceContent?.Description}
          </div>
          {/* <Button2 buttontext={"Learn More"} /> */}
          <button
            className=" text-black  w-fit font-body font-medium mt-5 mx-auto md:mx-0 sm:mt-2 py-1 px-2 lg:py-2 lg:px-4 text-sm md:text-md border border-orange-600 hover:border-transparent rounded"
            onClick={() => window.scroll(0, 730)}
          >
            Learn more
          </button>
        </div>
        <div className="p-1 lg:w-1/2 flex justify-center " data-aos="fade-down">
          <img src="../SDKFaceImg.png" alt="sdk face Image" />
        </div>
      </div>
    </div>
  );
}

export default SDKIntro;
