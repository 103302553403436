/* eslint-disable jsx-a11y/img-redundant-alt */
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import HoolvaSDK from "../Components/HoolvaSDK";
import SDKFeatures from "../Components/SDKFeatures";
import SDKGetStarted from "../Components/SDKGetStarted";
import SDKInstall from "../Components/SDKInstall";
import SDKIntro from "../Components/SDKIntro";
import SDKUseCases from "../Components/SDKUseCases";
import env from "../config";
import * as Actions from "../redux/actions";

function SDKPage() {
  const dispatch = useDispatch();

  // const ENV: any = useSelector(
  //   (state: any) => state?.Environment?.EnvironmentType
  // );

  const SDKData: any = useSelector(
    (state: any) => state?.SDKPage?.SDKPageContent
  );

  useEffect(() => {
    if (Object.keys(SDKData).length === 0) {
      getSDKPageData();
    }

    window.scrollTo(0, 0);
  }, []);

  const getSDKPageData = async () => {
    await axios
      .get(`${env}/api/sdk-page-content?populate=deep`)
      .then((response: any) => {
        dispatch(Actions.SDKPageAction(response?.data?.data?.attributes));
        // console.log(" SDK DATA ", response?.data?.data?.attributes);
      })
      .catch((error: any) => {
        // console.log("ENV", ENV);
        console.log("error", error);
      });
  };


  return (
    <div>
      <Header activePage="developers" />
      <br />
      <br />
      <SDKIntro SDKFaceContent={SDKData?.SDKFaceContent} />
      <HoolvaSDK HoolvaSDKContent={SDKData?.HoolvaSDKContent} />
      <SDKFeatures SDKFeatures={SDKData?.SDKFeatures} />
      <SDKInstall Installation={SDKData?.Installation} />
      <SDKUseCases UseCases={SDKData?.UseCases} />
      <SDKGetStarted />
      <Footer />
    </div>
  );
}

export default SDKPage;
