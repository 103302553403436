import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import * as Actions from "../redux/actions";

import {
  AvailableTickIcon,
  DownIcon,
  PartiallyAffectedIcon,
} from "../Components/SvgIcons";
import env from "../config";

function SystemStatusPage() {
  const dispatch = useDispatch();


  const SystemStatusData: any = useSelector(
    (state: any) => state?.SystemStatusPage?.SystemStatusContent
  );

  const getSystemStatusPageData = async () => {
    await axios
      .get(`${env}/api/system-status-page-content?populate=deep`)
      .then((response: any) => {
        dispatch(
          Actions.SystemStatusPageAction(response?.data?.data?.attributes)
        );
        // console.log("SYSTEM STATUS ACTION", response?.data?.data?.attributes);
      })
      .catch((error: any) => {
        // console.log("ENV", ENV);
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (Object.keys(SystemStatusData).length === 0) {
      getSystemStatusPageData();
    }

    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header activePage="system-status" />
      <div className="w-full py-28 pb-4 bg-[#F4F4F4]  ">
        <div className="text-xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mx-5 md:mx-20 p-2" data-aos="fade-in">
          <hr className="border-orange-600 w-24   mb-4 border-[1.5px] bg-orange-600" />
          <div className="text-paleblack">{SystemStatusData.Title} </div>
        </div>
        <div className=" p-1 flex flex-col mx-auto md:my-20 w-[95%] md:w-[90%] ">
          <div
            className="sm:bg-white bg-none sm:p-3 flex flex-col sm:flex-row mb-2 rounded-sm text-xs sm:text-sm md:text-md "
            data-aos="zoom-in"
          >
            <div className=" bg-white sm:bg-none my-1 p-2 sm:p-1 mx-2 flex rounded-sm flex-row-reverse sm:flex-row  items-center sm:w-[100px] justify-between text-[#404041] ">
              <AvailableTickIcon /> Available{" "}
            </div>
            <div className="bg-white sm:bg-none my-1 p-2 sm:p-1 mx-2 flex rounded-sm  flex-row-reverse sm:flex-row items-centers sm:w-[150px] justify-between text-[#404041] ">
              <PartiallyAffectedIcon />
              Partly Affected
            </div>
            <div className="bg-white sm:bg-none my-1 p-2 sm:p-1 mx-2 flex rounded-sm  flex-row-reverse sm:flex-row items-center sm:w-[80px] justify-between text-[#404041] ">
              <DownIcon />
              Down
            </div>
          </div>
          <div className="bg-white p-1 rounded-md flex flex-col  " data-aos="zoom-in">

            <div className="p-[1px]  flex justify-end">

              <div className="p-1 w-[65%]  sm:w-1/2   flex flex-row justify-end font-bold py-2 text-[#404041] text-xs sm:text-sm md:text-md xl:text-lg ">
                <div className=" p-1 sm:p-3  mx-1 w-1/3 text-center ">
                  <text>Production </text>
                  <text className=" hidden lg:inline">Server</text>
                </div>
                <div className="p-1 sm:p-3  mx-1 w-1/3  text-center ">

                  <text>Sandbox </text>
                  <text className=" hidden lg:inline">Server</text>
                </div>
                <div className="p-1 sm:p-3  mx-1 w-1/3  text-center ">

                  <text>Developer </text>
                  <text className=" hidden lg:inline">Portal</text>
                </div>
              </div>
            </div>


            <div className="p-1 flex flex-col my-1">
              {SystemStatusData?.SingleServerStatus?.map((node: any) => (
                <div
                  key={node.id}
                  className="  flex flex-row md:justify-end   border-t-2 border-gray-200 text-[#404041]"
                >
                  <div className="p-3  mx-1   w-[35%] sm:w-1/2 text-center flex justify-start sm:justify-center md:flex-1 my-2 font-bold text-xs sm:text-sm md:text-md xl:text-lg">
                    {node?.serverName}
                  </div>
                  <div className="w-[65%] sm:w-1/2  p-[1px] flex flex-row justify-evenly">


                    <div className="p-3  mx-1 w-1/3 text-center flex justify-center my-2  ">
                      {node.productionServerStatus === "available" ? (
                        <AvailableTickIcon />
                      ) : node.productionServerStatus === "partiallyaffected" ? (
                        <PartiallyAffectedIcon />
                      ) : (
                        <DownIcon />
                      )}
                    </div>
                    <div className="p-3  mx-1 w-1/3 text-center flex justify-center my-2  ">
                      {node.sandboxServerStatus === "available" ? (
                        <AvailableTickIcon />
                      ) : node.sandboxServerStatus === "partiallyaffected" ? (
                        <PartiallyAffectedIcon />
                      ) : (
                        <DownIcon />
                      )}
                    </div>
                    <div className="p-3  mx-1 w-1/3  text-center flex justify-center my-2 ">
                      {node.developerPortalStatus === "available" ? (
                        <AvailableTickIcon />
                      ) : node.developerPortalStatus === "partiallyaffected" ? (
                        <PartiallyAffectedIcon />
                      ) : (
                        <DownIcon />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SystemStatusPage;
