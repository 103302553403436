import React from "react";
import { Link } from "react-router-dom";
import {
  CodeIcon,
  PartnershipIcon,
  PricingIcon,
  SolutionIcon,
} from "./SvgIcons";

function ContactsBottomMenu() {
  return (
    <div className="w-full py-20 text-paleblack bg-[#FDE9D2] font-body ">
      <div className="flex flex-row  justify-center">
        <Link to="/hoolva-sdk">
          <div
            className="w-24 h-24 md:w-40 md:h-40 xl:w-60 xl:h-60 bg-white items-center justify-center flex rounded-md mx-2 flex-col cursor-pointer"
            data-aos="zoom-in"
          >
            <CodeIcon />
            <div className="xl:text-xl hidden md:block text-sm font-semibold font-body  xl:mt-16">Developers</div>
          </div>
        </Link>

        <Link to="/partnership">
          <div
            className="w-24 h-24 md:w-40 md:h-40 xl:w-60 xl:h-60 bg-white items-center justify-center flex rounded-md mx-2 flex-col cursor-pointer"
            data-aos="zoom-in"
          >
            <PartnershipIcon />
            <div className="xl:text-xl hidden md:block text-sm font-semibold font-body xl:mt-16">Partners</div>
          </div>
        </Link>

        <Link to="/solution">
          <div
            className="w-24 h-24 md:w-40 md:h-40  xl:w-60 xl:h-60 bg-white items-center justify-center flex rounded-md mx-2 flex-col cursor-pointer"
            data-aos="zoom-in"
          >


            <SolutionIcon />

            <div className="text-sm hidden md:block xl:text-xl font-body font-semibold xl:mt-16">Solution</div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default ContactsBottomMenu;
