import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Titles from "../Components/Titles";
import env from "../config";
import * as Actions from "../redux/actions";

function SupportPage() {
  const dispatch = useDispatch();

  const SupportData: any = useSelector(
    (state: any) => state?.SupportPage?.SupportPageContent
  );

  const getSupportPageData = async () => {
    await axios
      .get(`${env}/api/support-page-content?populate=deep`)
      .then((response: any) => {
        dispatch(Actions.SupportPageAction(response?.data?.data?.attributes));
        // console.log("SYSTEM STATUS ACTION", response?.data?.data?.attributes);
      })
      .catch((error: any) => {
        // console.log("ENV", ENV);
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (Object.keys(SupportData).length === 0) {
      getSupportPageData();
    }

    window.scrollTo(0, 0);
  }, []);

  const emptyState = {
    firstName: "",
    category: "",
    email: "",
    problemSummary: "",
  };

  const [formData, setFormData] = useState(emptyState);
  const [flag, setFlag] = useState("initial");

  const handleForm = (e: any) => {
    const newFormData: any = { ...formData };
    newFormData[e.target.id] = e.target.value;
    setFormData(newFormData);
  };

  const sendMail = () => {
    // e.preventDefault();
    console.log(formData);
    if (
      formData.category === "" ||
      formData.firstName === "" ||
      formData.problemSummary === "" ||
      formData.email === ""
    ) {
      setFlag("notcomplete");
    } else {
      setFlag("loading");
      axios
        .post(`${env}/api/support-emails`, {
          data: formData,
        })
        .then(() => {
          setFormData(emptyState);
          setFlag("success");
          setTimeout(() => {
            setFlag("initial");
          }, 10000);
        })
        .catch(() => {
          setFlag("error");
        });
    }
  };

  return (
    <div>
      <Header activePage="support" />
      <div
        className="w-auto xl:h-[685px] md:h-[485px] sm:h-[285px] h-[185px] pt-10 xl:pt-0"
        style={{
          backgroundImage: `url(${env}${SupportData?.FaceBackgroundimage?.data?.attributes?.url})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className=" text-white flex h-full items-center justify-center place-content-center">
          <div
            className="xl:w-5/12 content-cent text-center"
            data-aos="fade-down"
          >
            <div className=" text-3xl lg:text-4xl xl:text-5xl font-bold font-body">
              {SupportData?.Title}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pt-12 xl:pt-28 pb-4 bg-[#F4F4F4] ">
        <Titles
          color="paleblack"
          small_heading={SupportData?.SupportFormTitleContent?.Tagline}
          title={SupportData?.SupportFormTitleContent?.Title}
          sub_heading={SupportData?.SupportFormTitleContent?.Description}
        />

        {/* {flag === "inital" && (
        <div className="flex flex-col bg-red-50 text-red-500 w-3/6 mx-auto p-3 border-red-500 border-2 rounded-md mt-20">
          Error
        </div>
      )} */}
        <div
          className="flex flex-col bg-white w-[95%] xl:w-3/6 mx-auto py-3 rounded-md my-10"
          data-aos="fade-down"
        >
          {/* <form
          action="#"
          // onSubmit={(e) => sendMail(e)}
          // method="post"
          encType="text/plain"
        > */}

          <div className="flex flex-col lg:flex-row  px-3 justify-between">
            <div className="flex flex-col p-3 lg:w-1/2 mx-1">
              <label className="text-[#868686] fmd:text-[12px] text-[14px] lg:text-[16px]">
                First Name *
              </label>
              <input
                type="text"
                className="border-[1px] border-[#B0B0B0] rounded-md h-10 px-3 py-2 text-[14px] placeholder:text-[14px] placeholder:text-[#D7D7D7]"
                placeholder="First Name"
                id="firstName"
                value={formData.firstName}
                onChange={(e) => {
                  handleForm(e);
                  setFlag("initial");
                }}
                required
              />
            </div>
            <div className="flex flex-col  p-3 lg:w-1/2 mx-1">
              <label className="text-[#868686] md:text-[12px] text-[14px] lg:text-[16px]">
                Email *
              </label>
              <input
                required
                value={formData.email}
                className="border-[1px] border-[#B0B0B0] rounded-md h-10 px-3 py-2 text-14 placeholder:text-[14px] placeholder:text-[#D7D7D7]"
                placeholder="example@gmail.com"
                type="text"
                id="email"
                onChange={(e) => {
                  handleForm(e);
                  setFlag("initial");
                }}
              />
            </div>
          </div>
          <div className="flex flex-row  px-3 justify-between">
            <div className="flex flex-col p-3 w-full mx-1">
              <label className="text-[#868686] md:text-[12px] text-[14px] lg:text-[16px]">
                Category *
              </label>
              <select
                name="category"
                id="category"
                value={formData.category}
                onChange={(e) => {
                  handleForm(e);
                  setFlag("initial");
                }}
                className="border-[1px] border-[#B0B0B0] rounded-md h-10 px-3 py-2 text-sm placeholder:text-[14px]   placeholder:text-[#D7D7D7]"
              >
                <option value="Account Settings" selected>
                  Account Settings
                </option>
                <option value="User Portal">User Portal</option>
                <option value=" Web/Mobile/Desk Client">
                  Web/Mobile/Desk Client
                </option>
                <option value="SDK">SDK</option>
                <option value="Partnership">Partnership</option>
                <option value="Billing">Billing</option>
                <option value="Other">Others</option>
              </select>
              {/* <input
                required
                value={formData.email}
                className="border-[1px] border-[#B0B0B0] rounded-md h-10 px-3 py-2 text-14 placeholder:text-[14px]  placeholder:text-[#D7D7D7]"
                placeholder="category"
                type="category"
                id="email"
                onChange={(e) => {
                  handleForm(e);
                  setFlag("initial");
                }}
              /> */}
            </div>
          </div>
          <div className="px-4">
            <div className="flex flex-col p-3 ">
              <label
                htmlFor=""
                className="text-[#868686] md:text-[12px] text-[14px] lg:text-[16px]"
              >
                Problem Summary *
              </label>
              <textarea
                required
                value={formData.problemSummary}
                name=""
                id="problemSummary"
                onChange={(e) => {
                  handleForm(e);
                  setFlag("initial");
                }}
                // cols={30}
                rows={4}
                placeholder="In few words, discribe your issue..."
                className="border-[1px] border-[#B0B0B0] resize-none rounded-md  px-3 py-2 text-14 placeholder:text-[14px] placeholder:text-[#D7D7D7]"
              ></textarea>
            </div>
          </div>
          <div className="px-7 py-2 flex justify-center  xl:justify-end ">
            <button
              // type="submit"
              // type="reset"

              type="button"
              disabled={
                flag === "loading"
                  ? true
                  : flag === "initial"
                  ? false
                  : flag === "notcomplete"
                  ? true
                  : false
              }
              onClick={() => sendMail()}
              style={{
                cursor:
                  flag === "notcomplete" || flag === "loading"
                    ? "not-allowed"
                    : "pointer",
              }}
              className="bg-gradient-to-r from-orange-400 to-orange-600 text-black  font-body font-medium py-1 px-2 lg:py-2 lg:px-4 border border-orange-600 hover:border-transparent rounded text-sm md:text-md"
            >
              {flag === "loading"
                ? "Sending.."
                : flag === "initial"
                ? "Submit"
                : flag === "success"
                ? "Submit"
                : "Submit"}
            </button>
            {/* </form> */}
          </div>
          <div className="px-6">
            {flag === "success" && (
              <div className="flex flex-col bg-white text-[#89D074] w-full mx-auto p-2 md:p-3 border-[#89D074] border-2 rounded-md mt-2 font-semibold  md:text-sm text-xs">
                Success
                <div className=" py-1 md:py-2 text-[#89D074] font-normal">
                  Your response was submitted
                </div>
              </div>
            )}
            {flag === "error" && (
              <div className="flex flex-col bg-white text-[#EE5F5F] w-full mx-auto p-2 md:p-3 border-[#EE5F5F] border-2 rounded-md mt-2 font-semibold  md:text-sm text-xs">
                Error!
                <div className=" py-1 md:py-2 text-[#EE5F5F] font-normal">
                  There seems to be some error . Please try again
                </div>
              </div>
            )}
            {flag === "notcomplete" && (
              <div className="flex flex-col bg-white text-blue-500 w-full mx-auto p-2 md:p-3 border-blue-500 border-2 rounded-md mt-2  font-semibold  md:text-sm text-xs">
                Incomplete form
                <div className=" py-1 md:py-2 text-blue-500 font-normal">
                  Pleadse fill all fields to submit
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default SupportPage;
